<template>
  <b-card
    no-body
    class="shadow w-100 p-3"
    :title="item.title"
  >
    <h4>
      <span @click="switchMapFavourite(item.mapid, $event)">
        <span v-if="isMapTop(item.mapid)">
          <b-icon-map-fill />
        </span>
        <span v-else>
          <b-icon-map />
        </span>
      </span>
      &nbsp;&nbsp;<span
v-if="itemTitle(item)"
v-html="itemTitle(item)"
/>
    </h4>
    <b-card-text v-html="item.description" />
  </b-card>
</template>

<script>
import myMixin from "@/myMixin.js";
export default {
  name: "MapItemCard",
  mixins: [myMixin],

  props: {
    nr: { type: Number, default: 1 },
    channel: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    withImage(item) {
      return !(typeof item.image === "undefined" || item.image === "");
    },

    itemTitle(item) {
      if (typeof item.link === "undefined" || item.link === "") {
        return item.title;
      }
      return `<a href="${item.link}" target="_blank">${item.title}</a>`;
    },

    isMapTop(mapid) {
      return this.$store.state.topMapIds.includes(mapid);
    },
  },
};

//
</script>
