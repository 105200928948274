import { latLng } from "leaflet";
import { point } from "@turf/helpers";
import distance from "@turf/distance";
const turf = { point, distance };
// import { extendWith } from "lodash";

var myMixin = {
  methods: {
    // https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/

    doLog(text) {
      let debug = false;
      if (debug) {
        console.log(text);
      }
    },

    isTop(id) {
      return this.$store.state.topIds.includes(id);
    },

    isBase(id) {
      return this.$store.state.baseItemsArray[
        this.$store.state.currentBase
      ].includes(id);
    },

    hereCenter() {
      return latLng(this.$store.state.here.lat, this.$store.state.here.lon);
    },

    removeHtml(tit) {
      return tit.replace(/(<([^>]+)>)/gi, "").trim();
    },

    compareValues(key, order = "asc") {
      return function innerSort(a, b) {
        // if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        //   return 0;
        // }
        const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key];
        const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
          comparison = 1;
        } else if (varA < varB) {
          comparison = -1;
        }
        return order === "desc" ? comparison * -1 : comparison;
      };
    },

    buttonFilterFunction(elem, elemfilter) {
      let i;
      let content;
      const input = document.getElementById(elemfilter);
      const filter = input.value.toUpperCase();
      const buttonContainer = document.getElementById(elem);
      const buttons = buttonContainer.getElementsByClassName("btn");
      for (i = 0; i < buttons.length; i += 1) {
        content = buttons[i].value;
        if (content.toUpperCase().indexOf(filter) > -1) {
          buttons[i].style.display = "";
        } else {
          buttons[i].style.display = "none";
        }
      }
    },

    cardFilterFunction(elem, elemfilter) {
      let i;
      let content;
      const input = document.getElementById(elemfilter);
      const filter = input.value.toUpperCase();
      const cardContainer = document.getElementById(elem);
      const cards = cardContainer.getElementsByClassName("card");
      for (i = 0; i < cards.length; i += 1) {
        content = cards[i].innerText;
        if (content.toUpperCase().indexOf(filter) > -1) {
          cards[i].style.display = "";
        } else {
          cards[i].style.display = "none";
        }
      }
    },

    cardFilterFunctionValue(elem, searchtext) {
      let i;
      let content;
      const filter = searchtext.toUpperCase();
      const cardContainer = document.getElementById(elem);
      const cards = cardContainer.getElementsByClassName("card");
      for (i = 0; i < cards.length; i += 1) {
        content = cards[i].innerText;
        if (content.toUpperCase().indexOf(filter) > -1) {
          cards[i].style.display = "";
        } else {
          cards[i].style.display = "none";
        }
      }
    },

    mapcardShowHideFunction(elem, covnr) {
      let i;
      const cardContainer = document.getElementById(elem);
      const cards = cardContainer.getElementsByClassName("card");
      let displayVal = covnr > 0 ? "" : "none";
      let nr = Math.abs(covnr);
      for (i = 0; i < cards.length; i += 1) {
        let src = cards[i].getElementsByTagName("img")[0].src;
        if (src.includes("cov" + nr + ".png")) {
          cards[i].style.display = displayVal;
        }
      }
    },

    /*    switchFavourite(id) {
      this.switchBaseItem(id);
      var index = this.$store.state.topIds.indexOf(id);
      if (index >= 0) {
        this.$store.state.topIds.splice(index, 1);
        this.$store.commit("VOTE_FAVS", {
          channel: id,
          delta: -1
        });
      } else {
        this.$store.state.topIds.push(id);
        this.$store.commit("VOTE_FAVS", {
          channel: id,
          delta: 1
        });
      }
      index = this.$store.state.topIds.indexOf("");
      if (index >= 0) {
        this.$store.state.topIds.splice(index, 1);
      }
      this.$cookies.set("tops", this.$store.state.topIds.join(","));
      console.log(this.$store.state.topIds.join(","));
      //firebase.database;
    }, */

    distRound(meters) {
      const km = meters / 1000.0;
      if (km < 1.0) {
        return `${Math.round(meters)} m`;
      }
      let ndigits = 0;
      if (km < 1000 && km >= 100) {
        ndigits = 1;
      }
      if (km < 100) {
        ndigits = 3;
      }
      if (km > 500000) {
        let au = meters / 149597870700.0;
        return `${Math.round(au * 10 ** 3) / 10 ** 3} au`;
      }
      if (km > 1000) {
        return `${this.formatNumber(Math.round(km))} km`;
      }
      return `${Math.round((km * 10 ** ndigits) / 10 ** ndigits)} km`;
    },

    round(value, decimals) {
      return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * Math.floor(max));
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
    },

    switchBaseItem(id) {
      const nr = this.$store.state.currentBase;
      var index = this.$store.state.baseItemsArray[nr].indexOf(id);
      if (index >= 0) {
        this.$store.state.baseItemsArray[nr].splice(index, 1);
        this.$store.state.baseItemsArrayN[nr].splice(index, 1);
      } else {
        this.$store.state.baseItemsArray[nr].push(id);
        this.$store.state.baseItemsArrayN[nr].push(3);
        this.addBaseItem(nr, id, 3, "");
      }
      index = this.$store.state.baseItemsArray[nr].indexOf("");
      if (index >= 0) {
        this.$store.state.baseItemsArray[nr].splice(index, 1);
        this.$store.state.baseItemsArrayN[nr].splice(index, 1);
      }
      this.$store.commit("STORE_COOKIES", nr);
    },

    switchMapFavourite(id) {
      console.log("switchMapFavourite " + id);
      var index = this.$store.state.topMapIds.indexOf(id);
      let delta;
      if (index >= 0) {
        this.$store.state.topMapIds.splice(index, 1);
        delta = -1;
      } else {
        this.$store.state.topMapIds.push(id);
        delta = 1;
      }
      this.$store.commit("VOTE_MAP_FAVS", {
        channel: id,
        delta: delta
      });
      index = this.$store.state.topMapIds.indexOf("");
      if (index >= 0) {
        this.$store.state.topMapIds.splice(index, 1);
      }
      this.$cookies.set("maptops", this.$store.state.topMapIds.join(","));
      console.log(this.$store.state.topMapIds.join(","));
    },

    setCurrentBaseCookie() {
      this.$cookies.set("curtab", this.$store.state.currentBase);
    },

    setLocation(latlon) {
      this.$store.commit("SET_HERE", latlon);
      this.$store.dispatch("LOAD_ADDRESS");
      this.$store.dispatch("LOAD_BASEITEMS");
      this.$store.dispatch("LOAD_ALTITUDE");
      let ii =  this.$store.state.activeChannel.ID;
      if (typeof ii != "undefined") {
        // this.getItemsFrom(ii);
        this.$store.dispatch("LOAD_ITEMS", ii);
        this.$store.dispatch("LOAD_CHANNEL", ii);
      }
      this.updateMyLocations();
    },

    isHomes(lat, lon) {
      return this.$store.state.homes.some(loc => loc[0] == lat && loc[1] == lon);
    },

    updateMyLocations () {
      var updatedLocations = this.$store.state.myLocationItems.map(loc => {
        const from = turf.point([loc.lon, loc.lat]);
        const to = turf.point([this.$store.state.here.lon, this.$store.state.here.lat]);
        loc.dist = turf.distance(from, to, { units: "meters" });
        return loc;
      });

      this.$store.state.myLocationItems = updatedLocations.sort((a, b) => {
        let val;
        if (a.dist === b.dist) {
          val = 0;
        } else {
          val = b.dist > a.dist ? -1 : 1;
        }
        return val;
      });

    },


    isMyLocations(lat, lon) {
      return this.$store.state.myLocationItems.some(loc => this.round(loc.lat, 4) == lat && this.round(loc.lon, 4) == lon);
    },

    switchHome(lat, lon) {
      for (let i = 0; i < this.$store.state.homes.length; i += 1) {
        let loc = this.$store.state.homes[i];
        if (loc[0] == lat && loc[1] == lon) {
          this.$store.state.homes.splice(i, 1);
          this.$store.commit("STORE_HOMES");
          console.log("Home removed");
          return;
        }
      }
      this.$store.state.homes.push([lat, lon]);
      this.$store.commit("STORE_HOMES");
    },

    switchMyLocation() {
      let lat = this.round(this.$store.state.here.lat, 4);
      let lon = this.round(this.$store.state.here.lon, 4);
      let found = false;

      for (let i = 0; i < this.$store.state.myLocationItems.length; i += 1) {
        let itemnow = this.$store.state.myLocationItems[i];
        if (itemnow.lat == lat && itemnow.lon == lon) {
          this.$store.state.myLocationItems.splice(i, 1);
          // console.log("Location removed 2");
          found = true;
          break;
        }
      }
      if (!found) {
        let newLocationItem = {};
        newLocationItem.lat = lat;
        newLocationItem.lon = lon;
        newLocationItem.title = "...";
        newLocationItem.dist = 0;
        newLocationItem.description = this.$store.state.address;
        this.$store.state.myLocationItems.push(newLocationItem);

        // console.log("Location added");
      }
      this.updateMyLocationsCookie();

    },

    updateMyLocationsCookie() {
      let ml = this.$store.state.myLocationItems.map(loc => loc.lat + "/" + loc.lon).join(",");
      this.$cookies.set("mylocations", ml);
    },

    removeMyLocation(latToRemove, lonToRemove) {
      let found = false;
      let prevlength = this.$store.state.myLocationItems.length;

      let res = this.$store.state.myLocationItems.filter(loc => {
        return !(loc.lat === latToRemove && loc.lon === lonToRemove);
      });
      found = prevlength != res.length;
      if (found) {
        this.$store.state.myLocationItems = res;
        this.updateMyLocationsCookie();
      }
    },

    getDistance(lon1, lat1, lon2, lat2) {
      const from = turf.point([lon1, lat1]);
      const to = turf.point([lon2, lat2]);
      return turf.distance(from, to, { units: "meters" });
    },

    getChannelButtonClass(category) {
      //console.log(category);
      if (typeof category === "undefined") {
        category = "other";
      }
      const btnclass = {
        Culture: "success",
        Government: "primary",
        Admin: "primary",
        Earthquake: "danger",
        Maps: "danger",
        Events: "warning",
        Location: "secondary",
        Weather: "info",
        POI: "warning",
        Infrastructure: "warning",
        Statistics: "warning",
        Incidents: "outline-secondary",
        Nature: "danger",
        Mobility: "dark",
        SocialMedia: "outline-secondary",
        xxxx: "outline-secondary",
        Single: "secondary",
        Other: "secondary"
      };
      return `btn btn-sm btn-${btnclass[category]}`;
    },

    getItemsFrom(channelid) {
      //      console.log(channelid);
      this.$store.dispatch("LOAD_ITEMS", channelid);
      this.$store.dispatch("LOAD_CHANNEL", channelid);
      this.showResults(channelid);
      if (this.itemsLoaded) {
        //  this.showResults(channelid);
      }
    },

    showResults(id) {
      let path = "items";
      if (id == 304) {
        path = "swisstopo";
      }
      this.$router
        .push({
          path: path,
          query: {
            ch: id,
            lat: this.round(this.$store.state.here.lat, 5),
            lon: this.round(this.$store.state.here.lon, 5)
          }
        })
        .catch(() => {});
    },

    getBaseItem(id) {
      return this.baseItems[id];
    },

    addBaseItem(nr, id, n, channel) {
      this.$store.state.baseItems[nr][id] = { ID: id, n: n, channel: channel };
      this.$store.dispatch("LOAD_BASEITEMS");
    },

    getBaseItemChannelCategory(id) {
      return this.getChannelCategory(id);
    },

    getChannelTitle(id) {
      for (let i = 0; i < this.$store.state.channels.length; i++) {
        if (this.$store.state.channels[i].ID == id) {
          return this.$store.state.channels[i].title;
        }
      }
      return this.baseItems[this.$store.state.currentBase][id].channel;
      //return "...";
      /*      this.$store.state.channels.every(channel => {
        if (channel.ID == id) {
          return  channel.category;
        }
      }); */
    },

    getBaseItemChannelTitle(id) {
      return this.baseItems[this.$store.state.currentBase][id].channel;
    },

    getChannelCategory(id) {
      for (let i = 0; i < this.$store.state.channels.length; i++) {
        if (this.$store.state.channels[i].ID == id) {
          return this.$store.state.channels[i].category;
        }
      }
      return "Other";
      /*      this.$store.state.channels.every(channel => {
        if (channel.ID == id) {
          return  channel.category;
        }
      }); */
    },

    getBaseItemStr(id) {
      let bi = this.baseItems[this.$store.state.currentBase][id];
      let str = bi["ID"];
      str = "";
      if (typeof bi["channel"] !== "undefined") {
        str += " " + bi["channel"];
      } else {
        str += id + ": (...)";
      }
      str = "";

      if (typeof bi["short"] !== "undefined") {
        let short = bi["short"];
        let dist = bi["dist"];
        for (var i = 0; i < short.length; i++) {
          if (short[i] === null && dist[i] !== null) {
            short[i] = "...";
            continue;
          }
          if (
            short[i] !== "-" &&
            short[i] !== null &&
            dist[i] !== null &&
            dist[i] !== null
          ) {
            // str += "<span class='basetextinfo'>" + short[i] + "</span>";
            str += "<b>" + short[i] + "</b>";
            if (typeof dist[i] !== "undefined") {
              var d = dist[i];
              if (d !== 0 && d !== "" && d !== "0" && !isNaN(d) && d >= 1) {
                str += " [" + this.distRound(d) + "]";
              }
            }
            str += " | ";
          }
        }
      } else {
        str += " (...)";
      }
      if (str.endsWith(" | ")) {
        str = str.substring(0, str.length - 3);
      }
      /*  if (typeof bi["dist"] !== "undefined") {
        var d = bi["dist"];
        if (d !== 0 && d !== "" && d !== "0") {
          d += "";
          d = d.split(",");
          d = d.map(x => this.distRound(x));
          str += " in " + d.join(", ");
        }
      }
      */
      return str;
    },

    getSys() {
      /**
       * JavaScript Client Detection
       * (C) viazenetti GmbH (Christian Ludwig)
       */
      // https://stackoverflow.com/a/18706818/2798806

      var unknown = "-";

      // screen
      var screenSize = "";
      if (screen.width) {
        var width = screen.width ? screen.width : "";
        var height = screen.height ? screen.height : "";
        screenSize += "" + width + " x " + height;
      }

      // browser
      var nVer = navigator.appVersion;
      var nAgt = navigator.userAgent;
      var browser = navigator.appName;
      var version = "" + parseFloat(navigator.appVersion);
      var majorVersion = parseInt(navigator.appVersion, 10);
      var nameOffset, verOffset, ix;
      var fullUserAgent = navigator.userAgent;

      // Opera
      if ((verOffset = nAgt.indexOf("Opera")) != -1) {
        browser = "Opera";
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf("Version")) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Opera Next
      if ((verOffset = nAgt.indexOf("OPR")) != -1) {
        browser = "Opera";
        version = nAgt.substring(verOffset + 4);
      }
      // Legacy Edge
      else if ((verOffset = nAgt.indexOf("Edge")) != -1) {
        browser = "Microsoft Legacy Edge";
        version = nAgt.substring(verOffset + 5);
      }
      // Edge (Chromium)
      else if ((verOffset = nAgt.indexOf("Edg")) != -1) {
        browser = "Microsoft Edge";
        version = nAgt.substring(verOffset + 4);
      }
      // MSIE
      else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
        browser = "Microsoft Internet Explorer";
        version = nAgt.substring(verOffset + 5);
      }
      // Chrome
      else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
        browser = "Chrome";
        version = nAgt.substring(verOffset + 7);
      }
      // Safari
      else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
        browser = "Safari";
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf("Version")) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Firefox
      else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
        browser = "Firefox";
        version = nAgt.substring(verOffset + 8);
      }
      // MSIE 11+
      else if (nAgt.indexOf("Trident/") != -1) {
        browser = "Microsoft Internet Explorer";
        version = nAgt.substring(nAgt.indexOf("rv:") + 3);
      }
      // Other browsers
      else if (
        (nameOffset = nAgt.lastIndexOf(" ") + 1) <
        (verOffset = nAgt.lastIndexOf("/"))
      ) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() == browser.toUpperCase()) {
          browser = navigator.appName;
        }
      }
      // trim the version string
      if ((ix = version.indexOf(";")) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(" ")) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(")")) != -1) version = version.substring(0, ix);

      majorVersion = parseInt("" + version, 10);
      if (isNaN(majorVersion)) {
        version = "" + parseFloat(navigator.appVersion);
        majorVersion = parseInt(navigator.appVersion, 10);
      }

      // mobile version
      var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

      // cookie
      var cookieEnabled = navigator.cookieEnabled ? true : false;

      if (typeof navigator.cookieEnabled == "undefined" && !cookieEnabled) {
        document.cookie = "testcookie";
        cookieEnabled =
          document.cookie.indexOf("testcookie") != -1 ? true : false;
      }

      // system
      var os = unknown;
      var clientStrings = [
        { s: "Windows 10", r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: "Windows 8.1", r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: "Windows 8", r: /(Windows 8|Windows NT 6.2)/ },
        { s: "Windows 7", r: /(Windows 7|Windows NT 6.1)/ },
        { s: "Windows Vista", r: /Windows NT 6.0/ },
        { s: "Windows Server 2003", r: /Windows NT 5.2/ },
        { s: "Windows XP", r: /(Windows NT 5.1|Windows XP)/ },
        { s: "Windows 2000", r: /(Windows NT 5.0|Windows 2000)/ },
        { s: "Windows ME", r: /(Win 9x 4.90|Windows ME)/ },
        { s: "Windows 98", r: /(Windows 98|Win98)/ },
        { s: "Windows 95", r: /(Windows 95|Win95|Windows_95)/ },
        {
          s: "Windows NT 4.0",
          r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/
        },
        { s: "Windows CE", r: /Windows CE/ },
        { s: "Windows 3.11", r: /Win16/ },
        { s: "Android", r: /Android/ },
        { s: "Open BSD", r: /OpenBSD/ },
        { s: "Sun OS", r: /SunOS/ },
        { s: "Chrome OS", r: /CrOS/ },
        { s: "Linux", r: /(Linux|X11(?!.*CrOS))/ },
        { s: "iOS", r: /(iPhone|iPad|iPod)/ },
        { s: "Mac OS X", r: /Mac OS X/ },
        { s: "Mac OS", r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: "QNX", r: /QNX/ },
        { s: "UNIX", r: /UNIX/ },
        { s: "BeOS", r: /BeOS/ },
        { s: "OS/2", r: /OS\/2/ },
        {
          s: "Search Bot",
          r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/
        }
      ];
      for (var id in clientStrings) {
        var cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
          os = cs.s;
          break;
        }
      }

      var osVersion = unknown;

      if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = "Windows";
      }

      switch (os) {
        case "Mac OS":
        case "Mac OS X":
        case "Android":
          osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([._\d]+)/.exec(
            nAgt
          )[1];
          break;

        case "iOS":
          osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
          osVersion =
            osVersion[1] + "." + osVersion[2] + "." + (osVersion[3] | 0);
          break;
      }

      // flash (you'll need to include swfobject)
      /* script src="//ajax.googleapis.com/ajax/libs/swfobject/2.2/swfobject.js" */
      var flashVersion = "no check";
      /*       if (typeof swfobject != 'undefined') {
          var fv = swfobject.getFlashPlayerVersion();
          if (fv.major > 0) {
              flashVersion = fv.major + '.' + fv.minor + ' r' + fv.release;
          }
          else  {
              flashVersion = unknown;
          }
      } */

      var jscd = {
        screen: screenSize,
        browser: browser,
        browserVersion: version,
        browserMajorVersion: majorVersion,
        mobile: mobile,
        os: os,
        osVersion: osVersion,
        cookies: cookieEnabled,
        flashVersion: flashVersion,
        fullUserAgent: fullUserAgent
      };
      return jscd;
    }
  }
};
export default myMixin;
