var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('b-dropdown',{attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":"","right":"","text":"Maps"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('b-icon-map',{staticStyle:{"color":"rgb(0, 123, 255)","width":"16px","height":"16px","float":"right","padding-right":"0"}})]},proxy:true}])},[_c('b-dropdown-item',{attrs:{"target":"_blank","href":'https://www.openstreetmap.org/?mlat=' +
          _vm.item.lat +
          '&mlon=' +
          _vm.item.lon +
          '#map=' +
          _vm.getZoomlevel(_vm.herelongitude, _vm.herelatitude, _vm.item.lon, _vm.item.lat) +
          '/' +
          _vm.item.lat +
          '/' +
          _vm.item.lon}},[_c('img',{attrs:{"src":"/img/30px-Public-images-osm_logo.png","height":"24","width":"24"}}),_vm._v(" OpenStreetMap ")]),(_vm.isApple())?_c('span',[_c('b-dropdown-item',{attrs:{"target":"_blank","href":'https://maps.apple.com/?ll=' +
            _vm.item.lat +
            ',' +
            _vm.item.lon +
            '&z=' +
            _vm.getZoomlevel(_vm.herelongitude, _vm.herelatitude, _vm.item.lon, _vm.item.lat) +
            '&q=' + _vm.item.title}},[_c('img',{attrs:{"src":"/img/30-apple-map.png","height":"30","width":"30"}}),_vm._v(" Apple Maps App ")])],1):_vm._e(),_c('b-dropdown-item',{attrs:{"target":"_blank","href":/*       'https://www.google.com/maps/search/?api=1&query=' + 
                  item.lat + ',' + item.lon + ',' + getZoomlevel(herelongitude, herelatitude, item.lon, item.lat) + 'z' */
        'http://www.google.com/maps/place/' +
          _vm.item.lat +
          ',' +
          _vm.item.lon +
          '/@' +
          _vm.item.lat +
          ',' +
          _vm.item.lon +
          ',' +
          _vm.getZoomlevel(_vm.herelongitude, _vm.herelatitude, _vm.item.lon, _vm.item.lat) +
          'z'}},[_c('svgicon',{attrs:{"icon":"google-maps","width":"26","height":"26","color":"#050"}}),_vm._v(" Google Maps ")],1),_c('b-dropdown-item',{attrs:{"target":"_blank","href":'http://maps.google.com/?saddr=' +
          _vm.herelatitude +
          ',' +
          _vm.herelongitude +
          '&daddr=' +
          _vm.item.lat +
          ',' +
          _vm.item.lon}},[_c('svgicon',{attrs:{"icon":"directions","width":"24","height":"24","color":"#050"}}),_vm._v(" Google Maps Route ")],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }