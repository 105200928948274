<template>
  <div>
    <h2>Signin</h2>
    <form @submit="signin">
      <label for="email">Email:</label>
      <input
        type="email"
        id="email"
        v-model="email"
        required
      >

      <label for="password">Password:</label>
      <input
        type="password"
        id="password"
        v-model="password"
        required
      >

      <button type="submit">
        Signin
      </button>
    </form>
    <p
      v-if="errorMessage"
      class="error"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
    };
  },
  methods: {
    signin(event) {
      event.preventDefault();

      // Perform form validation
      if (!this.email || !this.password) {
        this.errorMessage = "Please enter email and password";
        return;
      }

      // Make a POST request to your PHP signin endpoint
      fetch(this.$store.state.serverRoot + "/server/signin.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Check the response from the server
          if (data.status == "success") {
            // Signin successful, perform necessary actions (e.g., redirect)
            // You can store the authentication token or user information in the browser's local storage or cookies
            // Make a GET request to the preferences endpoint
            console.log(data);
            this.$cookies.set("jwt", data.jwt);
            this.$store.commit("setLoggedIn", true);
            fetch(
              this.$store.state.serverRoot +
                "/server/preferences.php?userid=" +
                data.userid,
              {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then((response) => response.json())
              .then((data) => {
                // Handle the response data
                // The 'data' variable will contain the channels and locations preferences
                console.log(data);
              })
              .catch((error) => {
                console.error("Error:", error);
                // Handle any errors that occurred during the request
              });
          } else {
            // Signin failed, display the error message
            this.errorMessage = data.message;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.errorMessage = "An error occurred during signin";
        });
    },
  },
};
</script>

<style>
.error {
  color: red;
}
</style>
