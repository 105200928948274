<template>
  <div>
    <h3>News</h3><br>
    <!-- ITEMS records -->
    <div
      class="row"
      id="mynews"
    >
      <div class="col-md-12">
        <small>
          <div
            v-for="(item) in news"
            :key="item.ID"
          >
            <p align="left">
              {{ item.date }}, <b>{{ item.title }}</b>: {{ item.text }}</p>
          </div>
        </small>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'UmuNews',

    mounted() {
      // this.$store.dispatch('LOAD_NEWS');
   },

    computed: {
        ...mapState([
            'news',
        ]),
    },

};
</script>
