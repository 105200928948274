<template>
  <l-map
    ref="mapsmall"
    :zoom="zoom"
    :center="hereCenter()"
    :options="mapOptions"
    style="height: 300px; width: 100%"
    @click="setCenter"
  >
    <l-control-layers position="topright" />

    <l-tile-layer
      v-for="layer in layers"
      :key="layer.name"
      :name="layer.name"
      :visible="layer.visible"
      :url="layer.url"
      :attribution="layer.attribution"
      layer-type="base"
    />

    <l-marker
      :lat-lng="hereCenter()"
      :icon="redIcon"
    >
      <l-tooltip content="Your location" />
    </l-marker>
  </l-map>
</template>

<script>
import { latLng } from "leaflet";
import myMixin from "@/myMixin.js";
import { LMap, LTileLayer, LControlLayers } from "vue2-leaflet";
import L from 'leaflet';

export default {
  name: "MyMap",

  components: {
    LMap,
    LTileLayer,
    LControlLayers,
  },

  data() {
    return {
      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      zoom: 16,
      mapOptions: {
        zoomSnap: 0.5,
      },
      layers: [
        {
          url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
          name: "OSM",
          visible: !this.inSwitzerland(),
          attribution:
            '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
        },
        {
          name: "swisstopo",
          url:
            "https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.pixelkarte-farbe/default/current/3857/{z}/{x}/{y}.jpeg",
          visible: this.inSwitzerland(),
          attribution:
            '<a target="new" href="https://www.swisstopo.admin.ch/' +
            'internet/swisstopo/en/home.html">swisstopo</a>',
        },
        {
          url:
            "https://wmts10.geo.admin.ch/1.0.0/ch.swisstopo.swissimage/default/current/3857/{z}/{x}/{y}.jpeg",
          name: "swissimage",
          visible: false,
          attribution:
            '<a target="new" href="https://www.swisstopo.admin.ch/' +
            'internet/swisstopo/en/home.html">swisstopo</a>',
        },
      ],
      redIcon: new L.Icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      }),

      withPopup: latLng(47.377, 8.644),
      withTooltip: latLng(47.377, 8.544),
      currentZoom: 11.5,
      currentCenter: this.hereCenter,
      showParagraph: false,
      showMap: true,
      visible: true,
      markers: [],
      tooltips: [],
      markerBounds: [],
    };
  },
  mixins: [myMixin],
  methods: {
    setCenter(event) {
      console.log(event);
      this.withTooltip = event.latlng;
      this.setLocation({ lat: event.latlng.lat, lon: event.latlng.lng });
    },
    inSwitzerland() {
      let c = this.hereCenter();
      return c.lng > 5.91 && c.lng < 10.53 && c.lat > 45.79 && c.lat < 47.84;
    },
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
  },
};
</script>
