<template>
  <div>
    <h3>Swisstopo Open Data</h3>
    <div
      v-if="swisstopoChannel.subtitle"
      style="font-size: 14px"
      v-html="swisstopoChannel.subtitle"
    >
      {{ swisstopoChannel.subtitle }}
    </div>
    <hr>
    <div style="padding-bottom: 12px;">
      <b>My Swisstopo Favorites</b>
    </div>

    <div
      v-for="item in swisstopoItems"
      :key="item.id"
    >
      <template v-if="isSwisstopoTop(item.id)">
        <div
          align="left"
          style="font-size: 15px"
        >
          <span @click="switchSwisstopoFavourite(item.id, $event)">
            <span v-if="isSwisstopoTop(item.id)">
              <b-icon-star-fill />
            </span>
            <span v-else>
              <b-icon-star />
            </span>
          </span>
          <a
            :href="item.link"
            target="_blank"
          >{{
            removeHtml(item.title)
          }}</a>
        </div>
      </template>
    </div>
    <br>
    <div class="row">
      <div class="col-md-6">
        <input
          type="text"
          class="form-control"
          id="mySwisstopoFilter"
          @keyup="cardFilterFunction('mySwisstopoItems', 'mySwisstopoFilter')"
          placeholder="Filter swisstopo datasets ..."
        >
      </div>
    </div>
    <!-- ITEMS records -->
    <div
      class="row"
      id="mySwisstopoItems"
    >
      <div class="col-md-12">
        <div
          v-for="(item, index) in swisstopoItems"
          :key="item.ID"
        >
          <swisstopo-item-card
            :item="item"
            :swisstopoid="item.ID"
            :nr="index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SwisstopoItemCard from "./SwisstopoItemCard.vue";
import { mapState } from "vuex";
import myMixin from "@/myMixin.js";

export default {
  name: "TheSwisstopoList",

  metaInfo() {
    const descr = "Swisstopo Opem Data.";
    return {
      title: "swisstopo",
      meta: [
        { name: "description", content: descr },
      ]
    };
  },

  mixins: [myMixin],

  components: {
    SwisstopoItemCard
  },

  mounted() {
    this.$store.dispatch("LOAD_ITEMS", 304);
    this.$store.dispatch("LOAD_CHANNEL", 304);
  },

  computed: {
    ...mapState(["swisstopoItems", "swisstopoChannel"])
  },

  methods: {
    isSwisstopoTop(id) {
      return this.$store.state.topSwisstopoIds.includes(id);
    }
  }
};
</script>
