/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-top-left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="param(fill) #000" opacity="param(fill-opacity)" _stroke="param(outline)" stroke-width="param(outline-width) 0" stroke-opacity="param(outline-opacity)" d="M19 17.59L17.59 19 7 8.41V15H5V5h10v2H8.41L19 17.59z"/>'
  }
})
