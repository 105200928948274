<template>
  <b-navbar
    fixed="bottom"
    style="
          padding: 3px 0 0 0;
          border-top-color: gray;
          background-color: white;
          border-top-style: solid;
          border-top-width: 1px;
          font-size: 12px;
        "
  >
    <b-navbar-nav
      justified
      class="flex-grow-1"
    >
      <b-nav-item
        to="/"
        exact
        active-class="active2"
      >
        <b-icon-house-door-fill /><br> Home
      </b-nav-item>
    
      <b-nav-item
        to="/channels"
        active-class="active2"
      >
        <b-icon-flower1 />
        <br> Channels
      </b-nav-item>
      <!--       <b-nav-item
            :disabled="!hasItems"
            to="/items"
            active-class="active"
          >
            <b-icon-justify /><br>
            Results
          </b-nav-item> -->
      <!--                     <b-nav-item to="/favs">
                            <b-icon-star-fill></b-icon-star-fill><br>
                            Favs
                        </b-nav-item> -->
    
      <b-nav-item
        to="/maplist"
        active-class="active2"
      >
        <b-icon-map /><br>Online Maps
      </b-nav-item>
    
      <!--       <b-nav-item
            to="/swisstopo"
            active-class="active"
          >
            <b-icon-plus-square-fill /><br>
            swisstopo
          </b-nav-item> -->
      <!--       <b-nav-item
            to="/about"
            active-class="active"
          >
            <b-icon-info-circle /><br>
            About
          </b-nav-item> -->
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
export default {
    methods: {},
    computed: {
        hasItems() {
            return this.$store.state.items.length > 0;
        },
        currentRouteName() {
            return this.$router.currentRoute.path;
        },

    },
};
</script>

<style>
.abs-center-x {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.active2 {
    color: rgb(0, 123, 255) !important;
    font-weight: bool;
}
</style>
