/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-bottom-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="param(fill) #000" opacity="param(fill-opacity)" _stroke="param(outline)" stroke-width="param(outline-width) 0" stroke-opacity="param(outline-opacity)" d="M5 6.41L6.41 5 17 15.59V9h2v10H9v-2h6.59L5 6.41z"/>'
  }
})
