<template>
  <div>
    <h1>What's here?</h1>
    <p style="font-size: 14px">
      Your Favourite Channels Summary
    </p>
    <section v-if="!this.$store.state.serverOk">
      <b-alert
        variant="danger"
        show
      >
        We're sorry, we're not able to retrieve necessary data at the
        moment, please try back later.<br>
        <small>{{ this.$store.state.serverError }}</small>
      </b-alert>
    </section>
    <div>
      <b-tabs content-class="mt-3">
        <b-tab
          title="On the Move"
          :active="checkActive(0)"
          @click="setBaseSet(0)"
          @shown="reloadMap()"
        >
          <the-base-items />
        </b-tab>
        <b-tab
          title="At Home"
          :active="checkActive(1)"
          @click="setBaseSet(1)"
        >
          <the-base-items />
        </b-tab>
        <b-tab
          title="Map"
          :active="checkActive(2)"
          @click="setBaseSet(2)"
        >
          <the-base-items />
        </b-tab>
      </b-tabs>
    </div>
    <div v-if="checkActive(2)">
      <my-map ref="mymap" />
    </div>
    <br>      
    <div class="testexplain">
      If you are outside of Zurich or even Switzerland, you have a limited number of channels. To get a feeling for a better covered area, switch to 
      <b-button
        size="sm"
        variant="link"
        @click="mySetLocation(47.3729, 8.5412)"
        style="margin: 0; padding: 0; border: 0"
      >
        Zurich, Switzerland
      </b-button>
    </div>
    <br>
    <div id="myChannelItems3">
      <p style="font-size: 14px">
        <b>Some popular channels</b><br>(for a complete list select the
        <b-button
          size="sm"
          variant="link"
          to="/channels"
          style="margin:0; padding:0; border:0"
        >
          "Channels"
        </b-button>
        tab below):
      </p><div style="margin-top:12px">
        <span
          v-for="channel in this.channelHits"
          :key="channel.id"
        >
          <input
            type="button"
            @click="getItemsFrom(channel.ID)"
            :value="channel.title"
            :class="getChannelButtonClass(channel.category)"
            style="margin: 0 2px 6px 0"
          >
        </span>
      </div>
      <div style="margin-top:12px">
        <p style="font-size: 14px">
          <b>New channels</b>:
        </p>             
        <span
          v-for="channel in sortedChannels"
          :key="channel.id"
        >
          <template v-if="channel.new == '1'">
            <input
              type="button"
              @click="getItemsFrom(channel.ID)"
              :value="channel.title"
              :class="getChannelButtonClass(channel.category)"
              style="margin: 0 2px 6px 0"
            >
          </template>
        </span>
      </div>
    </div>
    <!-- <the-base-items /> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import myMixin from "@/myMixin.js";
// import _ from "lodash";
import TheBaseItems from "@/components/TheBaseItems.vue";
import MyMap from "@/components/MyMap.vue";
import {point} from "@turf/helpers";
import bearing from "@turf/bearing";
import distance from "@turf/distance";

const turf = { point, bearing, distance };

export default {
  name: "TheInputList",
  data() {
    return {
      formerCategory: "",
      currentCenter: this.hereCenter,
      showParagraph: false,
      showMap: true,
      count: 0,
    };
  },

  metaInfo() {
    const descr = "Spatial information aggregated and specially prepared for your current or any location."
    return {
      title: "Location based information",
      titleTemplate: "UmMiUme - %s",
      meta: [
        {
          name: "description",
          content: descr,
        },

        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://ummiume.mapresso.com/" },
        {
          property: "og:title",
          content: "UmMiUme: Location based information",
        },
        { property: "og:site_name", content: "UmMiUme" },
        {
          property: "og:description",
          content:
            "Spatial information aggregated and specially prepared for your current or any location.",
        },
        { property: "og:image", content: "" },

        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://ummiume.mapresso.com/",
        },
        {
          property: "twitter:title",
          content: "UmMiUme: Location based information",
        },
        {
          property: "twitter:description",
          content:
            "Spatial information aggregated and specially prepared for your current or any location.",
        },
        { property: "twitter:image", content: "" },

        { name: "robots", content: "index,follow" },
      ],
    };
  },


  mixins: [myMixin],

  components: {
    TheBaseItems,
    MyMap,
  },

  props: [],

  computed: {
    areaFilter: {
      get: function () {
        return this.$store.state.areaFilter;
      },
      set: function () {},
    },

            sortedChannels() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            // this.channels.sort(this.compareValues('csort'));
            //et cs = _.orderBy(this.channels, ["csort", "count"], ["asc", "desc"]);
            return this.channels;
            // return this.channels;
        },

    ...mapState(["channels", "channelHits", "itemsLoaded", "baseLoaded", "baseItems"]),
  },

  mounted() {
    // this.channels.sort(this.compareValues('category'));
    // this.doChanges();
    //$bvToast.show('my-toast');
    if (Math.random() > 0.66) {
     //  this.showToast();
    }
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
  },

  beforeCreate() {
    //   this.channels.sort(this.compareValues('category'));
  },

  watch: {
    itemsLoaded: function () {
      //       this.showResults(123)
      // console.log(this.items);
    },
  },

  methods: {

     mySetLocation(lat, lon) {
      this.setLocation({ lat, lon });
      this.$router.push("/").catch(()=>{});
    },


    showToast() {
      // Use a shorter name for `this.$createElement`
      // Create a ID with a incremented count
      const id = `my-toast-${this.count++}`;

              const h = this.$createElement
        const vNodesTitle = h(
          'div',
          { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] },
          [
            h('strong', { class: 'mr-2' }, 'Hint'),
            //h('small', { class: 'ml-auto text-italics' }, '5 minutes ago')
          ]
        )

      // Create the toast
      this.$bvToast.toast(["Select \"Channels\" in the bottom menu to find other themes and channels."], {
        id: id,
        title: [vNodesTitle],
        variant: 'primary',
        noCloseButton: false,
        toaster: 'b-toaster-bottom-center',
      });
    },

    reloadMap: function () {
      // Did no work, only the resize-Event works
      //this.$refs.mymap.$refs.mapsmall.mapObject.invalidateSize();
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    },

    setBaseSet(nr) {
      this.$store.state.currentBase = nr;
      this.setCurrentBaseCookie();
      this.reloadMap();
    },

    changeCategory(category) {
      if (category == this.formerCategory) {
        return false;
      } else {
        this.formerCategory = category;
        return true;
      }
    },

    checkActive(nr) {
      /*       if (nr === 0) {
              for (var i = 0; i < this.$store.state.homes.length; i++) {
                let home = this.$store.state.homes[i];
                let distance = this.getDistance(
                  home[1],
                  home[0],
                  this.$store.state.here.lon,
                  this.$store.state.here.lat
                );
                console.log(distance);
                if (distance <= 300) {
                  console.log("At Home");
                  this.setBaseSet(1);
                  return true;
                }
              }
              console.log("Not at Home");
                  this.setBaseSet(0);
              return false;
            } else {
                  this.setBaseSet(nr); */
      return nr == this.$store.state.currentBase;
    },

    getDistance(lon1, lat1, lon2, lat2) {
      const from = turf.point([lon1, lat1]);
      const to = turf.point([lon2, lat2]);
      return turf.distance(from, to, { units: "meters" });
    },

    isTop(id) {
      return this.$store.state.topIds.includes(id);
    },

    isId(chan, id) {
      return chan.ID === id;
    },

    doChanges(ary) {
      let oldval = "";
      ary.forEach((channel) => {
        if (channel.category !== oldval) {
          channel.change = 1;
          oldval = channel.category;
        } else {
          channel.change = 0;
        }
      });
      return ary;
    },

    changeCheckbox(how) {
      this.$store.commit("SET_AREAFILTER", how);
      this.$store.dispatch("LOAD_CHANNELS");
      this.doChanges(this.channels);
    },
  },
};
</script>
