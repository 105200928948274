/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'directions': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="param(fill) #000" opacity="param(fill-opacity)" _stroke="param(outline)" stroke-width="param(outline-width) 0" stroke-opacity="param(outline-opacity)" d="M14 14.5V12h-4v3H8v-4a1 1 0 011-1h5V7.5l3.5 3.5m4.21.29l-9-9h-.01a.996.996 0 00-1.41 0l-9 9c-.39.39-.39 1.03 0 1.42l9 9c.39.38 1.02.39 1.42 0l9-9c.39-.39.39-1.03 0-1.42z"/>'
  }
})
