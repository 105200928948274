<template>
  <b-card
    no-body
    class="shadow w-100 p-3"
    :title="item.title"
  >
    <h4>
      <span @click="switchSwisstopoFavourite(item.id, $event)">
        <span v-if="isSwisstopoTop(item.id)">
          <b-icon-star-fill />
        </span>
        <span v-else>
          <b-icon-star />
        </span>
      </span>
          &nbsp;&nbsp;<span
v-if="itemTitle(item)"
v-html="itemTitle(item)"
/>
    </h4>
    <!--        <b-card-text>
         <v-clamp autoresize :max-lines="4">
         {{ item.description }}
        <template #after="{ toggle, expanded, clamped }">
        <button
          v-if="expanded || clamped"
          class="toggle btn btn-sm"
          @click="toggle"
        >{{'+'}}</button>
      </template>
      </v-clamp>
      </b-card-text>  -->
    <b-card-text v-html="item.description" /> 
    <!--       <b-card-text v-line-clamp="5" v-html="item.description"> </b-card-text> 
 -->       
  </b-card>
</template>

<script>
import myMixin from "@/myMixin.js";
// import VClamp from 'vue-clamp'


export default {
  name: "SwisstopoItemCard",
  mixins: [myMixin],
  props: {
    nr: { type: Number, default: 1 },
    channel: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  methods: {
    withImage(item) {
      return !(typeof item.image === "undefined" || item.image === "");
    },

    itemTitle(item) {
      if (typeof item.link === "undefined" || item.link === "") {
        return item.title;
      }
      return `<a href="${item.link}" target="_blank">${item.title}</a>`;
    },

    isSwisstopoTop(mapid) {
      return this.$store.state.topMapIds.includes(mapid);
    },
  },
};

//
</script>
<style>
.toggle {
    margin-left:.4rem;
    border:.05rem solid #5755d9;
    padding: 0 3px 0 3px
}
</style>