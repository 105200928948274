<template>
  <b-card
    no-body
    class="shadow w-100 p-3 card card-accent-danger mycard mb-3"
    :title="channel.title"
    :id="getId(nr)"
  >
    <b-card-text>
      <span @click="switchBaseItem(channel.ID, $event)">
        <span v-if="isBase(channel.ID)">
          <b-icon-star-fill />
        </span>
        <span v-else>
          <b-icon-star />
        </span>      
      </span>
      &nbsp; &nbsp; &nbsp;

      <input
        type="button"
        @click="getItemsFrom(channel.ID)"
        :value="channel.title"
        :class="getChannelButtonClass(channel.category)"
        style="margin: 0 8px 0px 0"
      >

      <span
        @click="switchBaseItem(channel.ID, $event)"
        style="padding-right: 5px"
      >
        <span v-html="this.getCoverage(channel)" />
        <span v-if="isDirect(channel.lastBuildDate)">
          &nbsp;<b-icon-circle-fill
            variant="success"
            font-scale="0.66"
          />
        </span>
      </span>
      <span style="display:none">{{ channel.category }} </span>
    </b-card-text>
    <b-card-text>
      <b>{{ channel.subtitle }}</b>

      <!-- <b-collapse id="collapse-details" class="mt-2" style="display:block"> -->
      <span
        v-if="channel.site_description"
        style="display: block"
      >
        <i>{{ channel.site_description }}</i></span>
      <span><br>{{ channel.linktext }}
        <a
          :href="channel.link"
          target="_blank"
        >{{ channel.link }}</a>
        <span
          style="display: none"
        >{ {{ channel.category }} {{ channel.description }} {{ channel.keywords }}
        </span>
      </span>
      <!-- </b-collapse> -->
    </b-card-text>
  </b-card>
</template>

<script>
import moment from "moment";
import myMixin from "@/myMixin.js";

export default {
  name: "ChannelMiniCard",
  props: {
    channel: {
      type: Object,
      default() {
        return {};
      },
    },
    nr: { type: Number, default: 1 },
    herelatitude: { type: Number, default: 47 },
    herelongitude: { type: Number, default: 9 },
    serverRoot: { type: String, default: "" },
  },
  mixins: [myMixin],
  computed: {},
  methods: {
    coverageWorld(channel) {
      return channel.coverage === "World";
    },

    getSubtitle(t) {
      var r = "";
      if (t.subtitle !== "") {
        r = t.subtitle;
      }
      return r;
    },

    getFooter(t) {
      //  {{  channel.description }} | {{ channel.site_keywords }} | {{ channel.site_description }}

      var r = "";
      //r += '"' + t.site_description +  '" - <br>' + t.description + " [" + t.ID  + "] "  + t.tech + " ("  + t.count+ ")";
      r +=
        t.description +
        " - <br>" +
        t.ID +
        " [" +
        t.ID +
        "] " +
        t.tech +
        " (" +
        t.count +
        ")";
      r += "<br>" + t.keywords;
      return r;
    },

    getCoverage(t) {
      //return "<i>" + t.category + "</i> - " + t.coverage;
      // return " [<i>" + t.coverage + "</i>]";
      //return " [" + t.coverage + "]";
      return " " + t.coverage + " ";
    },

    channelLastUpdate(when) {
      const lbd = moment(when, "YYYY-MM-DD");
      if (!lbd.isValid() || moment().diff(lbd, "days") < 1) {
        return " direct";
      }
      return ` &nbsp; ${lbd.fromNow()}`;
    },

    isDirect(when) {
      const lbd = moment(when, "YYYY-MM-DD");
      return !lbd.isValid() || moment().diff(lbd, "days") < 1;
    },
    /* 
        setLocation(lat, lon) {
            this.$store.state.here.lat = lat;
            this.$store.state.here.lon = lon;
            this.$store.dispatch('LOAD_ADDRESS');
            this.$store.dispatch("LOAD_BASEITEMS");

            this.$router.push('/');
        },
 */

    /*       setNewLocation(lat, lon) {
            this.setLocation(lat, lon);
            this.$router.push('/');
        },
 */
    withImage(item) {
      return !(typeof item.image === "undefined" || item.image === "");
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
    },

    getId(nr) {
      return `itemid${nr}`;
    },

    changeHeart() {
      alert("heart");
    },
  },
};
</script>

<style>
.mycard {
  text-align: right;
  font-size: 12px !important;
  line-height: 14px !important;
}
</style>