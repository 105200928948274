<template>
  <span>
    <b-dropdown
      size="lg"
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      right
      text="Maps"
    >          <template
                 #button-content
               >
                 <b-icon-map
                   style="
                color: rgb(0, 123, 255);
                width: 16px;
                height: 16px;
                float: right;
                padding-right: 0;
              "
                 />
               </template>
      <b-dropdown-item
        target="_blank"
        :href="
          'https://www.openstreetmap.org/?mlat=' +
            item.lat +
            '&amp;mlon=' +
            item.lon +
            '#map=' +
            getZoomlevel(herelongitude, herelatitude, item.lon, item.lat) +
            '/' +
            item.lat +
            '/' +
            item.lon
        "
      >
        <img
          src="/img/30px-Public-images-osm_logo.png"
          height="24"
          width="24"
        >
        OpenStreetMap
      </b-dropdown-item>

      <span v-if="isApple()">
        <b-dropdown-item
          target="_blank"
 
          :href="
            'https://maps.apple.com/?ll=' +
              item.lat +
              ',' +
              item.lon +
              '&amp;z=' +
              getZoomlevel(herelongitude, herelatitude, item.lon, item.lat) +
              '&amp;q=' + item.title
          "
        >
          <img
            src="/img/30-apple-map.png"
            height="30"
            width="30"
          >
          Apple Maps App
        </b-dropdown-item>
      </span>

      <!-- <a :href="'https://www.google.com/maps/search/?api=1&amp;query=' + item.lat + ',' + item.lon + '&amp;z=' + getZoomlevel(herelongitude, herelatitude, item.lon, item.lat)"
                >GM</a></b-dropdown-item>&nbsp; -->
				
      <b-dropdown-item
        target="_blank"
        :href="
          /*       'https://www.google.com/maps/search/?api=1&query=' + 
                    item.lat + ',' + item.lon + ',' + getZoomlevel(herelongitude, herelatitude, item.lon, item.lat) + 'z' */
          'http://www.google.com/maps/place/' +
            item.lat +
            ',' +
            item.lon +
            '/@' +
            item.lat +
            ',' +
            item.lon +
            ',' +
            getZoomlevel(herelongitude, herelatitude, item.lon, item.lat) +
            'z'
        "
      ><svgicon
        icon="google-maps"
        width="26"
        height="26"
        color="#050"
      />        Google Maps
      </b-dropdown-item>

      <b-dropdown-item
        target="_blank"
        :href="
          'http://maps.google.com/?saddr=' +
            herelatitude +
            ',' +
            herelongitude +
            '&daddr=' +
            item.lat +
            ',' +
            item.lon
        "
      ><svgicon
        icon="directions"
        width="24"
        height="24"
        color="#050"
      />
        Google Maps Route
      </b-dropdown-item>

    </b-dropdown>
  </span>
</template>

<script>
import myMixin from "@/myMixin.js";

export default {
  name: "ItemHeader",
  props: {
    herelongitude: { type: Number, default: 1 },
    herelatitude: { type: Number, default: 1 },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mixins: [myMixin],
  computed: {},
  methods: {
    getZoomlevel(lon1, lat1, lon2, lat2) {
      let zoomLevel;
      const latDiff = Math.abs(lat2 - lat1);
      const lonDiff = Math.abs(lon2 - lon1);
      const maxDiff = 0.5 * (lonDiff > latDiff ? lonDiff : latDiff);
      if (maxDiff < 360 / 2 ** 20) {
        zoomLevel = 21;
      } else {
        zoomLevel = Math.round(
          -1 * (Math.log(maxDiff) / Math.log(2) - Math.log(360) / Math.log(2))
        );
        if (zoomLevel < 1) zoomLevel = 1;
      }
      return zoomLevel;
    },
    isApple() {
      return ["Mac OS X", "Mac OS", "iOS"].includes(
        this.$store.state.clientSystem.os
      );
    },
  },

  watch: {},
};
</script>

<style>
</style>