//import '@babel/polyfill'
import 'unfetch/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
// import Vuex from 'vuex'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
//import {VueSession} from 'vue-session'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
import VueSessionStorage from 'vue-sessionstorage'
Vue.use(VueSessionStorage)

import './plugins/bootstrap-vue'
import App from './App.vue'
import store from './store/store'
import { routes } from './routes'

import VueRouter  from 'vue-router'
const axios = require('axios');

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

Vue.use(VueRouter);

import VueCookies from 'vue-cookies';

// Vue.use(Vuex)
Vue.use(VueCookies);
Vue.$cookies.config('365d');

// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
// Vue.use(LMap, LTileLayer,  LMarker);

import VueMeta from 'vue-meta';
Vue.use(VueMeta);

import * as Vue2Leaflet from 'vue2-leaflet'; 
Vue.use(Vue2Leaflet);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import draggable from "vuedraggable";
Vue.use(draggable);

import VueSVGIcon from 'vue-svgicon';
Vue.use(VueSVGIcon);

import lineClamp from 'vue-line-clamp'
Vue.use(lineClamp, {
  // plugin options
})

import {
  LMap, LTileLayer, LMarker, LPopup, LTooltip, LControlLayers
} from 'vue2-leaflet';
import L, { Icon } from 'leaflet';
Vue.component('LMap', LMap);
Vue.component('LTileLayer', LTileLayer);
Vue.component('LMarker', LMarker);
Vue.component('LPopup', LPopup);
Vue.component('LTooltip', LTooltip);
Vue.component('LControlLayers', LControlLayers);

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(L);
L.NumberedDivIcon = L.Icon.extend({
  options: {
      // EDIT THIS TO POINT TO THE FILE AT http://www.charliecroom.com/marker_hole.png (or your own marker)
      // iconUrl: '@assets/images/marker_hole.png',
      iconUrl: 'https://my.mapresso.com/markerhole.png',
      number: '',
      shadowUrl: null,
      iconSize: new L.Point(25, 41),
      iconAnchor: new L.Point(13, 41),
      popupAnchor: new L.Point(0, -33),
      /*
      iconAnchor: (Point)
      popupAnchor: (Point)
      */
      className: 'leaflet-div-icon'
  },

  createIcon: function() {
      var div = document.createElement('div');
      var img = this._createImg(this.options['iconUrl']);
      var numdiv = document.createElement('div');
      numdiv.setAttribute("class", "number");
      numdiv.innerHTML = this.options['number'] || '';
      div.appendChild(img);
      div.appendChild(numdiv);
      this._setIconStyles(div, 'icon');
      return div;
  },

  //you could change this to add a shadow like in the normal marker if you really wanted
  createShadow: function() {
      return null;
  }
});

const token = localStorage.getItem('user-token')
if (token) {
  axios.defaults.headers.common['Authorization'] = token
}

require('./assets/css/styles.css');


// import { BIcon } from 'bootstrap-vue'
// Vue.component('b-icon', BIcon)

Vue.config.productionTip = false

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
