<template>
  <div>
    <l-map
      :zoom="4.5"
      :center="hereCenter()"
      :options="mapOptions"
      :bounds="markerBounds"

      style="height: 300px; width: 100%"
      @update:center="centerUpdate"
      @click="setCenter"
      ref="map_favlocations"
    >
      <l-tile-layer
        :url="url"
        :attribution="attribution"
      />
      <!-- <v-locatecontrol /> -->
      <!-- :item="locationitem(rec, myLocations)" -->

      <v-geosearch :options="geosearchOptions" />

      <l-marker
        :lat-lng="hereCenter()"
        :icon="redIcon"
      >
        <l-tooltip content="Your location" />
      </l-marker>
      <l-marker
        v-for="(marker, index) in markers"
        :key="index"
        :lat-lng="marker"
        :icon="markerIcon[index]"
        @click="innerClick(index)"
      >
        <l-tooltip :content="tooltips[index]" />
      </l-marker>
    </l-map>
    <div
      class="row"
      id="myItems"
    >
      <div class="col-md-12">
        <div
          v-for="(rec, index) in myLocationItems"
          :key="index"
        >
          <item-card
            :item="rec"            
            :nr="index"
            :herelatitude="hlat"
            :herelongitude="hlon"
            :channel="activeChannel"
            :server-root="serverRoot"
            :isfavlocations="1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import myMixin from "@/myMixin.js";
//import * as turf from "@turf/turf";

import { latLng } from "leaflet";
import { OpenStreetMapProvider } from "leaflet-geosearch";
//import Vue2LeafletLocatecontrol from "vue2-leaflet-locatecontrol";
import VGeosearch from "vue2-leaflet-geosearch";
import L from "leaflet";
import { mapState } from "vuex";
import ItemCard from "@/components/ItemCard.vue";

export default {
  name: "MapPref",
  mixins: [myMixin],
  //rops: ["items", "nr", "channel"],
  components: {
    VGeosearch,
    ItemCard,

    // "v-locatecontrol": Vue2LeafletLocatecontrol,
  },

  mounted() {
    this.$store.dispatch("LOAD_ITEMS", 7);
    // setTimeout(function(){ this.$refs.map_favlocations.mapObject.invalidateSize()}, 100);

    this.buildFavMarkers();
  },

  beforeMount() {
    //  setTimeout(function(){ this.$refs.map_favlocations.mapObject.invalidateSize()}, 100);

    this.buildFavMarkers();
    if (!this.hasItems) {
      // this.$router.push("/");
    }
  },
  computed: {
    ...mapState([
      "here",
      "myLocationItems",
      "activeChannel",
      "serverRoot",
      "items_loading",
      "isfavlocations",
      "items_errored",
    ]),
    hlat() {
      return 1 * this.$store.state.here.lat;
    },
    hlon() {
      return 1 * this.$store.state.here.lon;
    },
    hasItems() {
      return this.$store.state.items.length > 0;
    },
  },

  watch: {
    myLocationItems: function () {
      //     this.doLog("watch");
     //  this.buildMarkers();
    },
    //markerBounds: "setBoundsWatch", 
    },

  methods: {
    reloadMap: function () {
      console.log("realod");
      // Did no work, only the resize-Event works
      //this.$refs.mymap.$refs.mapsmall.mapObject.invalidateSize();
      this.$refs.map_favlocations.mapObject.invalidateSize();
      setTimeout(function () {
        window.dispatchEvent(new Event("resize"));
      }, 250);
    },

    withImage(item) {
      return !(typeof item.image === "undefined" || item.image === "");
    },

    itemTitle(item) {
      if (typeof item.link === "undefined" || item.link === "") {
        return item.title;
      }
      return `<a href="${item.link}" target="_blank">${item.title}</a>`;
    },

    isMapTop(mapid) {
      return this.$store.state.topMapIds.includes(mapid);
    },

    setCenter(event) {
      //console.log(event);
      if (typeof event.originalEvent.srcElement.form === "object") {
        return;
      }
      this.withTooltip = event.latlng;
      this.setLocation({ lat: event.latlng.lat, lon: event.latlng.lng });
      //this.reloadMap();
    },

    buildFavMarkers() {
     this.doLog("buildFavMarkers");
      this.markers = [];
      this.tooltips = [];
      this.markerIcon = [];
      let latmin = this.hlat;
      let latmax = latmin;
      let lonmin = this.hlon;
      let lonmax = lonmin;
      L.NumberedDivIcon = L.Icon.extend({
        options: {
          // EDIT THIS TO POINT TO THE FILE AT http://www.charliecroom.com/marker_hole.png (or your own marker)
          // iconUrl: '@assets/images/marker_hole.png',
          iconUrl: "https://my.mapresso.com/markerhole.png",
          number: "",
          shadowUrl: null,
          iconSize: new L.Point(25, 41),
          iconAnchor: new L.Point(13, 41),
          popupAnchor: new L.Point(0, -33),
          /*
                                        iconAnchor: (Point)
                                        popupAnchor: (Point)
                                        */
          className: "leaflet-div-icon2",
        },

        createIcon: function () {
          var div = document.createElement("div");
          var img = this._createImg(this.options["iconUrl"]);
          var numdiv = document.createElement("div");
          numdiv.setAttribute("class", "number");
          numdiv.innerHTML = this.options["number"] || "";
          div.appendChild(img);
          div.appendChild(numdiv);
          this._setIconStyles(div, "icon");
          return div;
        },

        //you could change this to add a shadow like in the normal marker if you really wanted
        createShadow: function () {
          return null;
        },
      });

      //this.doLog("buildMarkers " + this.items.length);

      // this.$refs.map.mapObject.fitBounds(this.markers.map(m => { return [m.lat, m.lng] }))

      for (let i = 0; i < this.myLocationItems.length; i += 1) {
        this.markers.push(latLng(this.myLocationItems[i].lat, this.myLocationItems[i].lon));
          this.markerIcon.push(new L.NumberedDivIcon({ number: i + 1 }));

        this.tooltips.push(this.myLocationItems[i].title);
          lonmin = Math.min(this.myLocationItems[i].lon, lonmin);
          latmin = Math.min(this.myLocationItems[i].lat, latmin);
          lonmax = Math.max(this.myLocationItems[i].lon, lonmax);
          latmax = Math.max(this.myLocationItems[i].lat, latmax);
        
      }
      const bounds = [
        [latmin, lonmin],
        [latmax, lonmax],
      ];
      this.markerBounds = bounds;
    },



    centerUpdate(center) {
      // console.log("centerUpdate");
      this.currentCenter = center;
      if (this.$refs.map_favlocations) {
        this.$refs.map_favlocations.mapObject.invalidateSize();
      }
    },
  },

  // https://codesandbox.io/s/1piyh?file=/App.vue:72-132

  data() {
    return {
      zoom: 13,
      geosearchOptions: {
        // Important part Here
        provider: new OpenStreetMapProvider(),
        style: "bar",
        autoClose: true,
        position: "bottomleft",
        showMarker: true,
      },

      redIcon: new L.Icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      }),

      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.377, 8.644),
      withTooltip: latLng(47.377, 8.544),
      currentZoom: 4.5,
      currentCenter: this.hereCenter,
      showParagraph: false,
      showMap: true,
      visible: false,
      markers: [],
      markerBounds: [],

      mapOptions: {
        zoomSnap: 0.5,
      },
    };
  },
};
</script>

<style>
@import "~leaflet/dist/leaflet.css";
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import "https://unpkg.com/leaflet-geosearch@2.6.0/assets/css/leaflet.css";
</style>
