/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="param(fill) #000" opacity="param(fill-opacity)" _stroke="param(outline)" stroke-width="param(outline-width) 0" stroke-opacity="param(outline-opacity)" d="M13 20h-2V8l-5.5 5.5-1.42-1.42L12 4.16l7.92 7.92-1.42 1.42L13 8v12z"/>'
  }
})
