<template>
  <div
    style="
      margin: 0 0 20px 0;
      border-bottom-color: rgb(211, 211, 211);
      border-bottom-style: solid;
      border-bottom-width: 1px;
    "
  >
    <b-navbar
      position="top"
      style="padding: 0; background-color: white"
    >
      <b-navbar-brand
        to="/"
        style="margin: 0 12px 0 0"
      >
        <img
          src="../assets/images/logo.png"
          width="30"
          height="30"
        >
        <span style="color: rgb(177,33,102);font-size:16px">&nbsp;UmMiUme</span><!--<br>
<img src="http://localhost:8888/backend/server/img/umulogo-2.png" width=50 height=50>   
  <img src="http://localhost:8888/backend/server/img/logosquare.png" width=100 height=100>   
 -->
      </b-navbar-brand>
      <b-navbar-nav>
        <b-nav-text @click="refreshLocation">
          <small
            style="font-size: 75%"
            v-html="showCoords"
          />&nbsp;&nbsp;&nbsp;&nbsp;
          <!--           <img
            src="/img/24-standortaktualisierung.png"
            height="20"
            width="20"
          > -->
          <b-icon-arrow-clockwise
            style="
              color: gray;
              width: 16px;
              height: 16px;
              float: right;
              margin-top: 4px;
              margin-left: 12px;
            "
          /><span class="sr-only">Search</span>
        </b-nav-text>
      </b-navbar-nav>
      <b-navbar-nav
        class="ml-auto"
        style="float: right; padding: 0"
      >
        <!--         <b-nav-item :href="getCurrentUrl" target="_blank">
              <b-icon-box-arrow-up></b-icon-box-arrow-up>
            </b-nav-item> -->

        <b-dropdown
          size="lg"
          variant="link"
          toggle-class="text-decoration-none"
          no-caret
          right
          text="Right align"
        >
          <template
            v-if="!isAuth"
            #button-content
          >
            <b-icon-gear
              style="
                color: gray;
                width: 20px;
                height: 20px;
                float: right;
                padding-right: 0;
              "
            /><span class="sr-only">Search</span>
          </template>

          <template
            v-else
            #button-content
          >
            <b-icon-person style="color: red; font-size: 12" />
            <span class="sr-only">Search</span>
          </template>
          <b-dropdown-item to="/locations">
            <span style="font-size: 90%">My Locations</span>
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            @click="onLogout"
            to="/about"
          >
            <span style="font-size: 90%">About</span>
          </b-dropdown-item>
          <!-- 
          <b-dropdown-divider />
          <b-dropdown-item
            to="/usersignin"
          >
            <span style="font-size: 90%">Login</span>
          </b-dropdown-item>

          <b-dropdown-divider />
          <b-dropdown-item
            to="/usersignup"
          >
            <span style="font-size: 90%">Sign up</span>
          </b-dropdown-item>

          <b-dropdown-divider />
          <b-dropdown-item
            @click="onLogout"
          >
            <span style="font-size: 90%">Logout</span>
          </b-dropdown-item> -->

          <!--           <b-dropdown-divider />
          <b-dropdown-text style="width: 240px;">
            *** Not yet! ***
          </b-dropdown-text>
          <b-dropdown-item
            :disabled="isAuth == true"
            to="/signin"
          >
            Sign In
          </b-dropdown-item>
          <b-dropdown-item
            :disabled="isAuth == true"
            to="/signup"
          >
            Sign Up
          </b-dropdown-item>
          <b-dropdown-divider />
          <b-dropdown-item
            :disabled="isAuth == false"
            @click="onLogout"
          >
            Logout
          </b-dropdown-item> -->
        </b-dropdown>

        <!--                 <b-nav-item>
                    <router-link class="nav-link pr-3" to="/login"><b-icon-person></b-icon-person></router-link>
                    
                </b-nav-item> -->
      </b-navbar-nav>
    </b-navbar>
    <div style="line-height: 80%; margin-bottom: 6px">
      <!--       <div v-if="isLoggedIn">
        Welcome, User!
      </div>
      <div v-else>
        Please sign in to view this content.
      </div> -->

      <small
        style="font-size: 75%"
        v-html="showHere"
      />&nbsp;&nbsp;&nbsp;
      <span @click="switchMyLocation()">
        <span
          v-if="
            isMyLocations(
              this.round(this.$store.state.here.lat, 4),
              this.round(this.$store.state.here.lon, 4)
            )
          "
        >
          <b-icon-bookmark-fill />
        </span>
        <span v-else>
          <b-icon-bookmark />
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import myMixin from "@/myMixin.js";

export default {
  mixins: [myMixin],
  methods: {
    onLogout() {
      this.$store.dispatch("logout");
    },
    
    refreshLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.getPosition,
          this.writeError
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    },

    getPosition(position) {
      if (position) {
        this.setLocation({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
      }
    },

    writeError() {
      console.log(
        "getCurrentPosition() and watchPosition() no longer work on insecure origins."
      );
    },

    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },

    /*     setLocation(latlon) {
              this.$store.commit("SET_HERE", latlon);
              this.$store.dispatch("LOAD_ADDRESS");
              this.$store.dispatch("LOAD_BASEITEMS");
           }, */
  },

  computed: {
    isAuth() {
      return this.$store.getters.isAuthenticated;
    },
    getCurrentUrl() {
      return document.location.href + this.$route.fullPath;
    },

    getLoginLink() {
      return this.$store.state.serverRoot + `/server/login.php`;
    },

    showCoords() {
      // return this.$store.state.here.lat.toFixed(4) + " / " +
      //     this.$store.state.here.lon.toFixed(4);
      return (
        this.round(this.$store.state.here.lat, 4) +
        "&nbsp;/&nbsp;" +
        this.round(this.$store.state.here.lon, 4) +
        "&nbsp;●&nbsp;" +
        this.round(this.$store.state.here.altitude, 0) +
        "&nbsp;m"
      );
    },

    showHere() {
      let a = this.$store.state.address;
      if (a === null) {
        return "???";
      }
      let start = Math.round(a.length / 2) - 3;
      for (var i = start; i <= a.length; i++) {
        if (a.substring(i, i + 2) === ", ") {
          a = a.substring(0, i + 1) + "<br>" + a.substring(i + 1, a.length);
          return a;
        }
      }
      return this.$store.state.address;
    },
  },
  watch: {
    showHere: function () {
      this.$store.dispatch("LOAD_ADDRESS");
      this.$store.dispatch("LOAD_ALTITUDE");
      // this.$store.dispatch("LOAD_BASEITEMS");
    },
  },
};
</script>

<style>
.nav-link {
  display: block;
  padding: 0.12rem 1rem;
}
</style>