<template>
  <div>
    <h1>Online Maps</h1>
    <div
      v-if="mapChannel.subtitle"
      style="font-size: 14px"
      v-html="mapChannel.subtitle"
    >
      {{ mapChannel.subtitle }}
    </div>

    <div class="testexplain">
      The following list contains <b>external</b> online maps for the <b>current location</b>. Clicking on a link opens the map  site on a new page, centered on the current location if possible.<br>
      Click on the map icon to (un-)favorite a map.
    </div>
    <br>

    <div style="padding-bottom: 12px;">
      <h3>Favorites</h3>
    </div>

    <div
      v-for="item in mapItems"
      :key="item.mapid"
    >
      <template v-if="isMapTop(item.mapid)">
        <div
          class="fav shadow maptops"
        >
          <span
            @click="switchMapFavourite(item.mapid, $event)"
            style="margin: 1px 10px 3px 4px"
          >
            <span v-if="isMapTop(item.mapid)">
              <b-icon-map-fill />
            </span>
            <span v-else>
              <b-icon-map />
            </span>
          </span>
          <a
            :href="item.link"
            target="_blank"
          >{{
            removeHtml(item.title)
          }}</a>
        </div>
      </template>
    </div>
    <br>
    <div style="padding-bottom: 12px;">
      <h3>Full Directory</h3>
    </div>

<!--     <div class="legendtext">
      <b>Mapsite Coverage</b>: local:<img
        src="img/cov5.png"
        height="18"
        width="18"
      >&nbsp; | regional:<img
        src="img/cov4.png"
        height="18"
        width="18"
      >&nbsp; | national:<img
        src="img/cov3.png"
        height="18"
        width="18"
      >&nbsp; | continental:<img
        src="img/cov2.png"
        height="18"
        width="18"
      >&nbsp; | global:<img
        src="img/cov1.png"
        height="18"
        width="18"
      >
      <br><b>Special Sites</b>: Tourism:<img
        src="img/tourism.png"
        height="18"
        width="18"
      >&nbsp;&nbsp; Event:<img
        src="img/event.png"
        height="18"
        width="18"
      >
    </div>    
 -->    <div class="legendtext">
      <span style="line-height: 26px;font-size: 14px;"><b>Mapsite Coverage</b></span><br>
      <b-form-checkbox
        size="sm"
        v-model="status5"
        @change="mapcardShowHideFunction('myMapItems', status5)"
        value="5"
        unchecked-value="-5"
      >
        <img
          src="img/cov5.png"
          height="18"
          width="18"
        > local
      </b-form-checkbox>
      <b-form-checkbox
        size="sm"
        v-model="status4"
        @change="mapcardShowHideFunction('myMapItems', status4)"
        value="4"
        unchecked-value="-4"
      >
        <img
          src="img/cov4.png"
          height="18"
          width="18"
        > regional
      </b-form-checkbox>
      <b-form-checkbox
        size="sm"
        v-model="status3"
        @change="mapcardShowHideFunction('myMapItems', status3)"
        value="3"
        unchecked-value="-3"
      >
        <img
          src="img/cov3.png"
          height="18"
          width="18"
        > national
      </b-form-checkbox>
      <b-form-checkbox
        size="sm"
        v-model="status2"
        @change="mapcardShowHideFunction('myMapItems', status2)"
        value="2"
        unchecked-value="-2"
      >
        <img
          src="img/cov2.png"
          height="18"
          width="18"
        >  continental
      </b-form-checkbox>
      <b-form-checkbox
        size="sm"
        v-model="status1"
        @change="mapcardShowHideFunction('myMapItems', status1)"
        value="1"
        unchecked-value="-1"
      >
        <img
          src="img/cov1.png"
          height="18"
          width="18"
        >  global
      </b-form-checkbox>
      <b>Special Sites</b> [idea]: Tourism:<img
        src="img/tourism.png"
        height="18"
        width="18"
      >&nbsp;&nbsp; Event:<img
        src="img/event.png"
        height="18"
        width="18"
      >
    </div>
    
    <div class="row">
      <div class="col-md-6">
        <input
          type="text"
          class="form-control"
          id="myMapFilter"
          @keyup="cardFilterFunction('myMapItems', 'myMapFilter')"
          placeholder="Filter map sites ..."
        >
      </div>
    </div>
    <!-- ITEMS records -->
    <div
      class="row"
      id="myMapItems"
    >
      <div class="col-md-12">
        <div
          v-for="(item, index) in mapItems"
          :key="item.mapid"
        >
          <map-item-card
            :item="item"
            :mapid="item.mapid"
            :nr="index"
          />
        </div>
      </div>
    </div>

    <!-- <the-base-items /> -->
  </div>
</template>

<script>
import MapItemCard from "./MapItemCard.vue";
import { mapState } from "vuex";
import myMixin from "@/myMixin.js";

export default {
  name: "TheMapsList",
  data() {
    return {
      status5: 5,
      status4: 4,
      status3: 3,
      status2: 2,
      status1: 1
    };
  },

  metaInfo() {
    const descr = "A collection of map portals around you. Find there additional information about your location.";
    return {
      title: "Mapsites",
      meta: [
        { name: "description", content: descr },
      ]
    };
  },

  mixins: [myMixin],

  components: {
    MapItemCard,
  },

  mounted() {
    this.$store.dispatch("LOAD_ITEMS", 204);
    this.$store.dispatch("LOAD_CHANNEL", 204);
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
  },

  computed: {
    ...mapState(["mapItems", "mapChannel"]),
  },

  methods: {
           visiblemappref() {
      console.log("visiblemappref 1");
      this.$refs.map_favlocations.mapObject.invalidateSize();
    },   
    
    isMapTop(id) {
      return this.$store.state.topMapIds.includes(id + "");
    },
  }
};
</script>
