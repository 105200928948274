<template>
  <!--     <div style="font-size: 14px">
          Results for the selected favorites at startup.
        </div>
        <div class="testexplain">Test for ...</div>
     -->
  <div
    class="row"
    id="myBaseItems"
  >
    <div class="col-md-12">
      <draggable
        v-model="myList"
        group="bases"
        @start="drag = true"
        @end="drag = false"
        handle=".handle"
      >
        <transition-group
          type="transition"
          :name="'flip-list'"
        >
          <div
            v-for="baseItemID in myList"
            :key="baseItemID"
          >
            <b-card
              no-body
              class="fav shadow w-100 p-3"
              title="getChannelTitle(baseItemID)"
            >
              <!-- <input type="button" :disabled="baseItemID < 0" @click="getItemsFrom(baseItemID)" :value="getChannelTitle(baseItemID)" v-bind:class="getButtonClass(baseItemID)" style="margin: 0 0px 6px 0" /> -->

              <b-button
                :disabled="baseItemID < 0"
                @click="getItemsFrom(baseItemID)"
                :value="getChannelTitle(baseItemID)"
                :class="getButtonClass(baseItemID)"
                style="margin: 0 0px 3px 0"
              >
                {{ getChannelTitle(baseItemID) }}
              </b-button>
              &nbsp;&nbsp;
              <span @click="switchBaseItem(baseItemID, $event)">
                <span v-if="isBase(baseItemID)">
                  <b-icon-star-fill />
                </span>
                <span v-else>
                  <b-icon-star />
                </span>
                <span class="fa fa-align-justify handle">&#xF039; </span>
              </span>
              <b-card-text>
                <span v-if="getBaseItemStr(baseItemID) == ' (...)'">
                  <b-spinner
                    small
                    variant="secondary" 
                    label="Small Spinner"
                  /></span>
                <span v-else>
                  <span
                    v-html="getBaseItemStr(baseItemID)"
                    class="basetext"
                  />
                </span>
              </b-card-text>
            </b-card>
          </div>
        </transition-group>
      </draggable>
    </div>
  </div>
</template>

<script>
//import ItemCard from "@/components/ItemCard.vue";
import { mapState } from "vuex";
import myMixin from "@/myMixin.js";
import draggable from "vuedraggable";

//import ItemCard from './ItemCard.vue';

export default {
  name: "TheBaseItems",

  mixins: [myMixin],

  components: { 
    draggable, 
    },

  computed: {
    ...mapState([
      "baseItems",
      "nBaseItems",
      // "baseItemsArray",
      "baseLoaded",
    ]),
    myList: {
      get() {
        return this.$store.state.baseItemsArray[this.$store.state.currentBase];
      },
      set(value) {
        this.$store.commit("updateList", value);
      },
    },
  },

  watch: {
    itemsLoaded: function () {
      this.$router.push("items").catch(() => {});
    },   
  },

  data() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },

    getButtonClass(id) {
      //let catego = this.getChannelCategory(id);
      return this.getChannelButtonClass(this.getChannelCategory(id));
    },

  },
};
</script>

<style scoped>
.button {
  margin-top: 35px;
}

.handle {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}

.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}

input {
  display: inline-block;
  width: 50%;
}

.text {
  margin: 20px;
}

.basetext {
  font-size: 90%;
}

.basetextinfo {
  font-weight: 700;
}

b {
  font-weight: 700;
}
</style>