<template>
  <div id="app">
    <b-container fluid="sm">
      <b-row style="padding: 0 0 45px 0">
        <div class="col-md-12">
          <!-- <vue-pull-refresh :on-refresh="onRefresh" :config="this.config"
></vue-pull-refresh>                    -->
          <app-header />
          <transition
            name="slide"
            mode="out-in"
          >
            <router-view />
          </transition>
        </div>
      </b-row>
    </b-container>
    <app-footer />
  </div>
</template>

<script>
// http://192.168.1.12:8081/?ch=7&lat=45&lon=-1.236
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import myMixin from "@/myMixin.js";
// import VuePullRefresh from 'vue-pull-refresh';

// import TheInputList from '@/components/TheInputList.vue';
// import TheItemsList from '@/components/TheItemsList.vue';

export default {
  name: "App",
  metaInfo() {
    const descr = "Spatial information aggregated and specially prepared for your current or any location."
    return {
      title: "Location based information",
      titleTemplate: "UmMiUme - %s",
      meta: [
        {
          name: "description",
          content: descr,
        },

        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://ummiume.mapresso.com/" },
        {
          property: "og:title",
          content: "UmMiUme: Location based information",
        },
        { property: "og:site_name", content: "UmMiUme" },
        {
          property: "og:description",
          content:
            "Spatial information aggregated and specially prepared for your current or any location.",
        },
        { property: "og:image", content: "" },

        { property: "twitter:card", content: "summary_large_image" },
        {
          property: "twitter:url",
          content: "https://ummiume.mapresso.com/",
        },
        {
          property: "twitter:title",
          content: "UmMiUme: Location based information",
        },
        {
          property: "twitter:description",
          content:
            "Spatial information aggregated and specially prepared for your current or any location.",
        },
        { property: "twitter:image", content: "" },

        { name: "robots", content: "index,follow" },
      ],
    };
  },

  beforeMount() {

    let srvroot =
      process.env.NODE_ENV === "production"
        ? "https://ummiume.mapresso.com/backend"
        : "http://localhost:8888/backend";

    this.$store.commit("defineServerRoot", srvroot);

    let sys = this.getSys();
    this.$store.commit("SET_SYSTEM", sys);

    for (var i = 0; i < 3; i++) {
      this.getBaseCookie(i);
    }

    if (this.$route.query.lat && this.$route.query.lon) {
      this.setLocation({
        lat: this.$route.query.lat,
        lon: this.$route.query.lon,
      });
    } else {
      this.getLocation();
    }


    // MAP_TOPS
    this.$store.dispatch("LOAD_MAP_TOPS");

    // MY_LOCATIONS
    this.$store.dispatch("LOAD_MYLOCATIONS");

    // HOMES
    this.$store.dispatch("LOAD_HOMES");

    this.$store.state.currentBase = this.$cookies.get("curtab");
    if (this.$store.state.currentBase === null) {
      this.$store.state.currentBase = 0;
    }
  },

  components: {
    appHeader: Header,
    appFooter: Footer,
    //'vue-pull-refresh': VuePullRefresh
  },
  mounted: function () {

    // this.$store.commit("SET_FAVS", this.$cookies.get("favs"));
    this.$store.commit("SET_TOPS", this.$cookies.get("tops"));
    // this.$store.commit("SET_MAP_TOPS", this.$cookies.get("maptops"));
    this.$store.dispatch("LOAD_CHANNELS");
    // this.$store.dispatch("LOAD_HOMES");
    if (this.$route.query.ch) {
      var channelid = this.$route.query.ch;
      this.$store.dispatch("LOAD_ITEMS", channelid);
      this.$store.dispatch("LOAD_CHANNEL", channelid);
      if (this.itemsLoaded) {
        this.showResult(channelid);
      }
    }
    this.$store.dispatch("LOAD_NEWS");

    let shortChannelList = this.$cookies.get("shortchannels");
    if (!shortChannelList) {
      this.$cookies.set("shortchannels", this.$store.state.shortChannelList);
    }
  },

  data() {
    return {
      config: {
        errorLabel: "---error",
        startLabel: "---pull down start",
        readyLabel: "---ready to refresh",
        loadingLabel: "---loading",
        pullDownHeight: 10,
      },
    };
  },

  mixins: [myMixin],
  methods: {
    // https://observablehq.com/@jonthesquirrel/geolocation-using-promises
    onRefresh: function () {
      // return new Promise(function (resolve, reject) {
      return new Promise(function (resolve) {
        setTimeout(function () {
          resolve();
        }, 1000);
      });
    },

    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          this.getPosition,
          this.writeError
        );
      } else {
        console.log("Geolocation is not supported by this browser.");
        this.setLocation({
          lat: this.$store.state.here.lat,
          lon: this.$store.state.here.lon,
        });
      }
    },

    getPosition(position) {
      if (position) {
        this.setLocation({
          lat: position.coords.latitude,
          lon: position.coords.longitude,
        });
      }
    },

    getBaseCookie(nr) {
      let baseItemsString = this.$cookies.get("base_" + nr);

      if (baseItemsString === null) {
        baseItemsString = this.$store.state.baseItemsChannelsDefault[nr];

        let lat = this.$store.state.here.lat;
        let lon = this.$store.state.here.lon;

        let box = this.$store.state.schweizbox;
        if (
          lon > box[0] &&
          lat > box[1] &&
          lon < box[2] &&
          lat < box[3] &&
          this.$store.state.baseItemsChannelsDefaultCH[nr]
        ) {
          baseItemsString +=
            ";" + this.$store.state.baseItemsChannelsDefaultCH[nr];
        }

        box = this.$store.state.ktzhbox;
        if (
          lon > box[0] &&
          lat > box[1] &&
          lon < box[2] &&
          lat < box[3] &&
          this.$store.state.baseItemsChannelsDefaultZH[nr]
        ) {
          baseItemsString +=
            ";" + this.$store.state.baseItemsChannelsDefaultZH[nr];
        }
        this.$cookies.set("base_" + nr, baseItemsString);
      }

      if (baseItemsString === null) {
        return;
      }
      if (typeof baseItemsString != "undefined") {
        while (baseItemsString.slice(-1) === ";") {
          baseItemsString = baseItemsString.slice(0, -1);
        }
      }
      this.$store.commit("INIT_BASEITEMS", {
        baseItemsString: baseItemsString,
        nr: nr,
      });
    },

    writeError() {
      console.log(
        "getCurrentPosition() and watchPosition() no longer work on insecure origins."
      );
      this.setLocation({
        lat: this.$store.state.here.lat,
        lon: this.$store.state.here.lon,
      });
    },

    //setLocation_old(latlon) {
    // this.$store.commit("SET_HERE", latlon);
    // this.$store.dispatch("LOAD_ADDRESS");
    // this.$store.dispatch("LOAD_BASEITEMS");
    //  this.testSetLocation(latlon);
    // }
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.btn-sm {
  margin: 2px;
}
</style>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: px;
}

.pull-down-container {
  height: 10%;
  max-height: 10%;
  overflow-y: auto;
}

.pull-down-header {
  background-color: white;
  color: black;
}
</style>
