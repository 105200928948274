import axios from "axios";


const instance = axios.create({
  baseURL:      process.env.NODE_ENV === "production"
  ? "https://ummiume.mapresso.com/backend/server"
  : "http://localhost:8888/backend/server"
  // baseURL: 'https://www.googleapis.com/identitytoolkit/v3/relyingparty'
});

// instance.defaults.headers.common['SOMETHING'] = 'something'

export default instance;
