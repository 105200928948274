<template>
  <b-card
    no-body
    :class="this.getClass(nr)"
    :title="item.title"
    header-tag="header"
    :id="getId(nr)"
  >
    <h4 v-html="itemTitle(nr, item)" />

    <template #header>
      <div
        v-if="!item.sameLocationAsPrevious"
        :class="getHeaderClass(nr)"
      >
        <span
          class="mapnumber"
          style=""
        >{{ nr + 1 }}</span>&nbsp;
        <span v-if="item.dist == 0">Here <b-icon-geo-alt /></span>

        <span
          v-else
          v-html="distRound(item.dist)"
          :class="getHeaderClass(nr)"
        />
        {{ getDirection(herelongitude, herelatitude, item.lon, item.lat) }}
        <span v-if="item.dist < 40076 * 1000 / 2">
          <item-header
            :herelongitude="herelongitude"
            :herelatitude="herelatitude"
            :item="item"
          />
        </span>

        <!--         <a href="#">
          <span>&nbsp;&nbsp;&nbsp;&nbsp;<b-icon-chevron-double-up
            style="float: right"
          /></span>
        </a> -->
        <br>{{ getPeriod(item) }}
      </div>
    </template>
    <b-card-text
      v-if="!specialcase(item.description)"
      v-html="item.description"
    />
    <div v-if="specialcase(item.description)">
      <a
        href="#"
        @click="doStationtable(item.description)"
      >Nächste Abfahrten</a>
      <span :id="'station' + getStationId(item.description)" />
    </div>

    <span
      v-if="isLocationChannel()"
      @click="mySetLocation(item.lat, item.lon)"
      style="margin: 1px 10px 3px 4px"
    >
      <b-icon-arrow-up-right-circle-fill
        variant="success"
        style="width: 20px; height: 20px"
      />
    </span>

    <span v-if="isLocationChannel()">
      <b-button
        size="sm"
        variant="link"
        @click="mySetLocation(item.lat, item.lon)"
        style="margin: 0; padding: 0; border: 0"
      >
        Set as location
      </b-button>
      &nbsp;&nbsp;
      <span @click="removeMyLocation(item.lat, item.lon)">
        <span
          v-if="isMyLocations(this.round(item.lat, 4), this.round(item.lon, 4))"
        >
          <b-icon-bookmark-fill />
        </span>
        <span v-else>
          <b-icon-bookmark />
        </span>
      </span>
      <span
        @click="switchHome(item.lat, item.lon)"
        style="margin: 1px 10px 3px 4px"
      >
        <span v-if="isHomes(item.lat, item.lon)">
          <b-icon-house-fill style="width: 20px; height: 20px" />
        </span>
        <span v-else>
          <b-icon-house style="width: 20px; height: 20px" />
        </span>
      </span>
      <b-button
        size="sm"
        variant="link"
        @click="setHome(item.lat, item.lon)"
        style="margin: 0; padding: 0; border: 0"
      >
        To "At Home"
      </b-button>
    </span>
    <b-card-footer v-if="hasFooter(item)">
      {{ item.footer }}
    </b-card-footer>
    <!-- 
    <div v-if="withImage(item)">
      <b-card-img
        :src="item.image"
        alt="Image"
        bottom
      />
    </div> -->
  </b-card>
</template>

<script>
import ItemHeader from "@/components/ItemHeader.vue";

//import moment from "moment";
import { point } from "@turf/helpers";
import bearing from "@turf/bearing";
import distance from "@turf/distance";
const turf = { point, bearing, distance };

import myMixin from "@/myMixin.js";
import "../compiled-icons/index.js";

const axios = require("axios");
var dayjs = require("dayjs");
var relativeTime = require("dayjs/plugin/relativeTime");
dayjs.extend(relativeTime);

export default {
  name: "ItemCard",
  mixins: [myMixin],
  components: {
        ItemHeader,
    },

  props: {
    nr: { type: Number, default: 1 },
    herelatitude: { type: Number, default: 47 },
    herelongitude: { type: Number, default: 9 },
    serverRoot: { type: String, default: "" },
    channel: {
      type: Object,
      default() {
        return {};
      },
    },
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    isfavlocations: { type: Number, default: 0 },
  },

  methods: {
    getClass(nr) {
      if (
        (nr == 0 && this.channel.firstspecial > 0) ||
        this.channel.firstspecial == 9
      ) {
        return "card cardfirst shadow w-100 p-3 ";
      }
      return "card shadow w-100 p-3";
    },

    getHeaderClass(nr) {
      if (
        (nr == 0 && this.channel.firstspecial > 0) ||
        this.channel.firstspecial == 9
      ) {
        return "cardfirst";
      }
      return "";
    },

    hasFooter(item) {
      return !(typeof item.footer === "undefined");
    },

    isLocationChannel() {
      if (typeof this.isfavlocations === "undefined") {
        return false;
      }
      return this.isfavlocations == 1;
    },

    specialcase(descr) {
      if (descr == null) {
        return false;
      }
      return descr.includes("$$$");
    },

    mySetLocation(lat, lon) {
      // this.$store.state.here.lat = lat;
      // this.$store.state.here.lon = lon;
      // this.$store.dispatch('LOAD_ADDRESS');
      // this.$store.dispatch("LOAD_BASEITEMS");
      this.setLocation({ lat, lon });
      this.$router.push("/").catch(()=>{});
    },

    setHome(lat, lon) {
      console.log("sethome " + lat + lon);
    },

    doStationtable(descr) {
      const id = descr.split("$$$")[1];
      const config = {
        params: {
          id,
          limit: 12,
        },
      };
      axios
        .get(this.serverRoot + `/server/transport/stationtable.php`, config)
        .then((response) => {
          const stt = response.data;
          const spanid = `#station${config.params.id}`;
          document.querySelector(spanid).innerHTML = `<br>${stt}`;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStationId(descr) {
      return descr.split("$$$")[1];
    },

    withImage(item) {
      return !(typeof item.image === "undefined" || item.image === "");
    },

    itemTitle(nr, item) {
      if (typeof item.link === "undefined" || item.link === "") {
        let it = item.title;
        if (typeof it === "undefined") {
          it = "...";
        }
        // return r + ". " + it;
        return it;
      }
      // return `${r}. <a href="${item.link}" target="_blank">${item.title}</a>`;
      return `<a href="${item.link}" target="_blank">${item.title}</a>`;
    },

    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1'");
    },

    distRound(meters) {
      const km = meters / 1000.0;
      if (km < 1.0) {
        return `${Math.round(meters)} m`;
      }
      let ndigits = 0;
      if (km < 1000 && km >= 100) {
        ndigits = 1;
      }
      if (km < 100) {
        ndigits = 3;
      }
      if (km > 1000) {
        return `${this.formatNumber(Math.round(km))} km`;
      }
      return `${Math.round((km * 10 ** ndigits) / 10 ** ndigits)} km`;
    },

    getBearing(lon1, lat1, lon2, lat2) {
      if (lat1 > 90 || lat2 > 90) {
        return "";
      }
      const from = turf.point([lon1 * 1, lat1 * 1]);
      const to = turf.point([lon2 * 1, lat2 * 1]);
      return Math.round(turf.bearing(from, to));
    },

    getDistance(lon1, lat1, lon2, lat2) {
      const from = turf.point([lon1, lat1]);
      const to = turf.point([lon2, lat2]);
      return turf.distance(from, to, { units: "meters" });
    },

    getDirection(lon1, lat1, lon2, lat2) {
      if (lon1 === lon2 && lat1 === lat2) {
        return "";
      }
      if (this.getDistance(lon1, lat1, lon2, lat2) < 5) {
        return "";
      }
      const bearing = this.getBearing(lon1, lat1, lon2, lat2);
      const compass = bearing === "" ? "" : `${this.degToCompass(bearing, 8)}`;
      return `${compass}`;
    },

    getDirection0(lon1, lat1, lon2, lat2) {
      if (lon1 === lon2 && lat1 === lat2) {
        return "";
      }
      if (this.getDistance(lon1, lat1, lon2, lat2) < 5) {
        return "";
      }
      const bearing = this.getBearing(lon1, lat1, lon2, lat2);
      const compass =
        bearing === "" ? "" : `${this.degToCompass(bearing, 16)}°`;
      return `${compass} ${bearing} `;
    },

    degToCompass(bearing0, n) {
      //TODO no error handling for angles outside +- 360 degrees
      let bearing = bearing0;
      if (bearing < 0) {
        bearing += 360;
      }
      if (n === 8) {
        return ["N", "NE", "E", "SE", "S", "SW", "W", "NW"][
          Math.floor(bearing / 45 + 0.5) % 8
        ];
      } else if (n === 16) {
        return [
          "N",
          "NNE",
          "NE",
          "ENE",
          "E",
          "ESE",
          "SE",
          "SSE",
          "S",
          "SSW",
          "SW",
          "WSW",
          "W",
          "WNW",
          "NW",
          "NNW",
        ][Math.floor(bearing / 22.5 + 0.5) % 16];
      } else {
        return ["N", "E", "S", "W"][Math.floor(bearing / 90 + 0.5) % 4];
      }
    },

 getId(nr) {
      return `itemid${nr}`;
    },

    getPeriod(item) {
      let from = typeof item.eventfrom === "undefined" ? "" : item.eventfrom;
      from = from === null ? "" : from;
      let to = typeof item.eventto === "undefined" ? "" : item.eventto;
      to = to === null ? "" : to;
      if (from.substr(0, 4) === "0000") {
        from = "";
      }
      if (to.substr(0, 4) === "0000") {
        to = "";
      }
      if (from === "" && to === "") {
        return "";
      }

      if (from === to) {
        return dayjs(from.substr(0, 19)).fromNow();
      }
      const dtnow = dayjs();
      const dtstart = dayjs(from, "YYYY-MM-DD");
      const dtend = dayjs(to, "YYYY-MM-DD");

      const dtendf = dtend.format("DD.MM.YYYY");
      const dtstartf = dtstart.format("DD.MM.YYYY");
      if (dtstart < dtnow) {
        from = "";
      }

      if (from === "" && to === "") {
        return "";
      }
      // console.log(`*${from}* to *${to}*`);
      if (from == "") {
        return `(bis ${dtendf})`;
      }
      if (to === "") {
        return `(ab ${dtstartf})`;
      }
      if (from === to) {
        return `(<b>${dtstartf}</b>)`;
      }
      return `(${dtstartf} - ${dtendf})`;
    },
  },
};
</script>
<style scoped>
.mapnumber {
  color: white;
  background-color: rgb(35, 128, 208);
  font-size: 12px;
  padding: 1px 6px;
  border-radius: 20px;
  font-weight: 600;
  margin-right: 8px;
}
</style>