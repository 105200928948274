/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="param(fill) #000" opacity="param(fill-opacity)" _stroke="param(outline)" stroke-width="param(outline-width) 0" stroke-opacity="param(outline-opacity)" d="M4 11v2h12l-5.5 5.5 1.42 1.42L19.84 12l-7.92-7.92L10.5 5.5 16 11H4z"/>'
  }
})
