import TheInputList from './components/TheInputList.vue';
import TheItemsList from './components/TheItemsList.vue';
import About from './components/About.vue';
import Locations from './components/Locations.vue';
import TheMapList from './components/TheMapList.vue';
import TheChannelList from './components/TheChannelList.vue';
import TheSwisstopoList from './components/TheSwisstopoList.vue';
import TheFavsItemList from './components/TheFavsItemList.vue';
import SignupPage from './components/auth/signup.vue'
import SigninPage from './components/auth/signin.vue'
import UserSignup from './components/UserSignup.vue'
import UserSignin from './components/UserSignin.vue'

export const routes = [
    { path: '/', component: TheInputList},
    { path: '/items', component: TheItemsList},
    { path: '/about', component: About},
    { path: '/locations', component: Locations},
    { path: '/maplist', component: TheMapList},
    { path: '/channels', component: TheChannelList},
    { path: '/swisstopo', component: TheSwisstopoList},
    { path: '/favs', component: TheFavsItemList},
    { path: '/signup', component: SignupPage },
    { path: '/signin', component: SigninPage },
    { path: '/usersignup', component: UserSignup },
    { path: '/usersignin', component: UserSignin },

];