<template>
  <div>
    <h1>Channels</h1>
    <template>
      <div>
        <b-form-checkbox
          size="sm"
          v-model="areaFilter"
          @change="changeCheckbox"
          id="checkbox-areafilter"
          name="checkbox-areafilter"
        >
          Show only feeds in this area.
        </b-form-checkbox>
      </div>
    </template>   
    <div>
      <b-tabs content-class="mt-3">
        <b-tab
          title="Compact"
          :active="shortActive() == 1"
          @click="doShortActive(1)"
        >
          <div id="myChannelItems2">
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                id="myChannelFilter2"
                @keyup="buttonFilterFunction('myChannelItems2', 'myChannelFilter2')"
                placeholder="Filter channels by title ..."
              >
            </div>
            <div style="margin-top:12px">
              <i>New: </i>
              <span
                v-for="channel in sortedChannels"
                :key="channel.ID"
              >
                <template v-if="channel.new == '1'">
                  <input
                    type="button"
                    @click="getItemsFrom(channel.ID)"
                    :value="channel.title"
                    :class="getChannelButtonClass(channel.category)"
                    style="margin: 0 2px 6px 0"
                  >
                </template>
              </span>
            </div>
            <div>
              <span
                v-for="channel in sortedChannels"
                :key="channel.ID"
              >
                <template v-if="!['204', '304'].includes(channel.ID)">
                  <!--         <div v-if="changeCategory(channel.category)"> -->
                  <div v-if="channel.change == 1">
                    <hr> {{ channel.category }}
                  </div>
                  <input
                    type="button"
                    @click="getItemsFrom(channel.ID)"
                    :value="channel.title"
                    :class="getChannelButtonClass(channel.category)"
                    style="margin: 0 2px 6px 0"
                  >
                </template>
              </span>
              <!--          <hr> TEST<br>
              <input
                type="button"
                @click="getItemsFrom(304)"
                value="swisstopo"
                class="btn btn-sm btn-danger"
                style="margin: 0 2px 6px 0"
              >
                           <a href="/swisstopo" target="_self"><input
                type="button"
                to="/swisstopo"
                class="btn btn-sm btn-danger"
                value="swisstopo"
                style="margin: 0 2px 6px 0"
              ></a> -->
            </div>
          </div>
          <!-- <the-base-items /> -->
        </b-tab>

        <b-tab
          title="Details"
          :active="shortActive() == 0"
          @click="doShortActive(0)"
        >
          <div>
            <small>Ordered by popularity.
            </small>
            <br>
            <br>
            <!--          <b-icon-chevron-expand
                      v-b-toggle:collapse-details
                    ></b-icon-chevron-expand></span
                  ><br /> -->
          </div>

          <div class="row">
            <div class="col-md-6">
              <input
                type="text"
                class="form-control"
                id="myChannelFilter"
                @keyup="cardFilterFunction('myChannelItems', 'myChannelFilter')"
                placeholder="Filter channels ..."
              >
            </div>
            <div>
              <b-dropdown
                size="sm"
                id="dropdown-1"
                text="Categories"
                class="col-md-6"
              >
                <div
                  v-for="(category, index) in categories"
                  :key="index"
                >
                  <b-dropdown-item
                    @click="filter(category)"
                    :active="whichactive(category)"
                  >
                    {{ category }}
                  </b-dropdown-item>
                </div>
                <b-dropdown-divider />
                <b-dropdown-item
                  active
                  @click="filter('')"
                >
                  All categories
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>

          <div id="myChannelItems">
            <div class="row">
              <div class="col-md-12">
                <div
                  v-for="channel in sortedChannels0"
                  :key="channel.ID"
                >
                  <div v-if="channel.ID !== '204' && channel.ID !== '304'">
                    <channel-mini-card :channel="channel" />
                  </div>
                </div>
              </div>
            </div>
            <br><br>
            <hr>

            <!-- <h3>Old List Format</h3>
                    <div class="row">
                      <div class="col-md-12">
                        <div v-for="channel in channels" :key="channel.ID">
                          <div v-if="channel.ID !== '204' && channel.ID !== '304'">
                            <channel-card :channel="channel" />
                          </div>
                        </div>
                      </div>
                    </div> -->
          </div>

          <!--           <the-base-items /> -->
        </b-tab>
      </b-tabs>
    </div>

    <!-- List records -->
    <!-- <br>
            <div>
               <h4>Favorites</h4>
             <span v-for="channel in sortedChannels" :key="channel.ID">
<template v-if="isBase(channel.ID)">
    <div v-if="channel.newcategory">
        <hr /> {{ channel.category }}
    </div>
    <input type="button" @click="getItemsFrom(channel.ID)" :value="channel.title" v-bind:class="getChannelButtonClass(channel.category)" style="margin: 0 2px 6px 0" />
</template>
      </span>
    </div>
        <b-button size="sm" variant="outline-secondary" v-on:click="eraseCookie('base')">Erase Cookie</b-button>
 -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import myMixin from "@/myMixin.js";
import _ from "lodash";
// import ChannelCard from "@/components/ChannelCard.vue";
import ChannelMiniCard from "@/components/ChannelMiniCard.vue";

export default {
    data() {
        return {};
    },
    name: "TheChannelList",
    metaInfo() {
      const descr = "Available channels.";
      return {
        title: "Channel List",
        meta: [
          { name: "description", content: descr },
        ]
      };
    },

    mixins: [myMixin],

    components: {
        //  ChannelCard,
        ChannelMiniCard,
        //
    },

    props: [],

    mounted() {
        //this.channels.sort(this.compareValues('count', 'desc'));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
    },

    computed: {
        sortedChannels() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            // this.channels.sort(this.compareValues('csort'));
            let cs = _.orderBy(this.channels, ["csort", "count"], ["asc", "desc"]);
            this.doChanges(cs);
            return cs;
            // return this.channels;
        },

        areaFilter: {
            get: function() {
                return this.$store.state.areaFilter;
            },
            set: function() {},
        },


        sortedChannels0() {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties

            //TODO
            return this.channels.slice(0).sort(this.compareValues("count", "desc"));
            //   this.channels.sort(this.compareValues("ID", "asc"));
            // return this.channels;
        },
        ...mapState(["channels", "itemsLoaded", "categories"]),
    },

    watch: {
        itemsLoaded: function() {
            this.$router.push("items").catch(()=>{});
        },
    },

    methods: {
        eraseCookie: function(c_name) {
            this.$cookies.remove(c_name);
        },

        isTop(id) {
            return this.$store.state.topIds.includes(id);
        },

        isId(chan, id) {
            return chan.ID === id;
        },

        changeCheckbox(how) {
            this.$store.commit("SET_AREAFILTER", how);
            this.$store.dispatch("LOAD_CHANNELS");
        },

        filter(category) {
            this.cardFilterFunctionValue("myChannelItems", category);
            this.activecategory = category;
        },
        whichactive(category) {
       //     console.log(category + " " + this.activecategory);
            return category === this.activecategory;
        },

        shortActive() {
          return this.$store.state.shortChannelList;
        },

        doShortActive(which) {
          this.$store.state.shortChannelList = which;
          this.$cookies.set("shortchannels",  this.$store.state.shortChannelList);
        },

        doChanges(ary) {
            let oldval = "";
            ary.forEach((channel) => {
                if (channel.category !== oldval) {
                    channel.change = 1;
                    oldval = channel.category;
                } else {
                    channel.change = 0;
                }
            });
            return ary;
        },
    },
};
</script>
