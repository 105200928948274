<template>
  <div>
    <h3>Favorites</h3>
    <div style="font-size: 14px;">
      Results for the selected favorites.
    </div>
    <div class="testexplain">
      Test for a favorites page, where you see the results of a small number of items of some selected channels. There is no user interface for this selection. It is stored locally, better would be serverside (for a logged in user).
    </div>
    
    <br>
    <div class="row">
      <div class="col-md-6">
        <input
          type="text"
          class="form-control"
          id="filterFavItems"
          @keyup="cardFilterFunction('myFavItems', 'filterFavItems')"
          placeholder="Filter results ..."
        >
      </div>
    </div>
    <!-- ITEM records -->
    <div
      class="row"
      id="myFavItems"
    >
      <div class="col-md-12">
        <span v-if="favItems.length > 0" />
        <div
          v-for="(item, index) in favItems"
          :key="item.guid"
        >
          <item-card
            :item="item"
            :nr="index"
            :herelatitude="hlat"
            :herelongitude="hlon"
            :server-root="serverRoot"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ItemCard from '@/components/ItemCard.vue';
import { mapState } from 'vuex'
import myMixin from '@/myMixin.js'

export default {
    name: 'TheFavsItemList',

    mixins: [myMixin],

    components: {
        ItemCard,
    },

    computed: {
        ...mapState([
            'here', 'favItems', 'serverRoot',
        ]),
    },

    mounted() {
        let f = this.$cookies.get('favs');
        if (typeof f === 'undefined') {
            f = '905,3';
        }
        this.$store.dispatch('LOAD_FAVITEMS', f);

        this.hlat = this.here.lat;
        this.hlon = this.here.lon;

    },

    methods: {}
}
</script>
