import Vuex from "vuex";
import Vue from "vue";
const axios = require("axios");
//import * as turf from "@turf/turf";
import {point} from "@turf/helpers";
import bearing from "@turf/bearing";
import distance from "@turf/distance";
const turf = { point, bearing, distance };
// import user from "./modules/user";
// import auth from "./modules/auth";
import InfiniteLoading from "vue-infinite-loading";
import userAxios from "./axios-auth";
import { routes } from "../routes.js";
import myMixin from "@/myMixin.js";

// import { keyFor } from "core-js/fn/symbol";
// import { bindKey } from "lodash";
//import TheInputList from '../components/TheInputList.vue';

Vue.use(InfiniteLoading, {
  /* options */
});
//Vue.use(myMixin);

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
	serverOk: true,
	serverError: "",
    idToken: null,
    userId: null,
    user: null,
    here: {
      lat: 47.37299,
      lon: 8.54127,
      altitude: 500
    },
    serverRoot: "",
    favIds: [],


    topIds: [],
    topMapIds: [],
    topSwisstopoIds: [],

    mapTopDefault: "1024,1009,4,1027,1124",
    mapTopDefaultCH: "22,554",
    mapTopDefaultZH: "9,1062",
    
    myLocationsDefault_old: "46.387/8.1158,46.5118/8.9473,28.4895/-16.3205,45.0119/-1.1940,31.9328/35.8404,-5.7265/39.2957,17.4848/-92.0481,40.7579/-73.9857,47.3785/8.5540,12.3681/-1.5237,38.7918/15.2153,64.1478/-21.9401,34.4289/-119.7132,44.5091/7.1123,23.138/-82.3595",
    myLocationsDefault: "47.373/8.5413,45.43424/12.33853,-6.16221/39.19374,40.75578/-73.98643,46.02458/7.74808,46.16957/8.79628",

    myLocationItems: [],

    // Jungraujoch, Venedig oder Rom, Mekka, Sansibar, NYC, Zürich
    // Venedig 45.43424,12.33853
    // Sansibar -6.16221,39.19374
    // NYC 40.75578, -73.98643
    // Zermatt 46.02458,7.74808
    // Chicken Itza  10.683/-88.569

    homes: [],
    // homesDefault: [ [47.373, 8.5413], [47.3785, 8.554], [46.387, 8.1158], [46.5118, 8.9473]],
    // homesDefault: "47.373/8.5413,47.3785/8.554,46.387/8.1158,46.5118/8.9473",
    homesDefault: "47.373/8.5413",
    isHome: false,

    channels: [],
    channelHits: [],
    categories: [],
    negChannels: [
      {
        ID: "-101",
        title: "Elevation"
      },
      {
        ID: "-102",
        title: "Sun"
      }
    ],
    favChannels: [],
    areaFilter: true,
    activeChannel: {},
    mapChannel: {},
    swisstopoChannel: {},
    items: [],
    favLocations: [],
    mapItems: [],
    swisstopoItems: [],
    favItems: [],
    address: "",
    news: [],
    itemsLoaded: false,
    baseLoaded: 0,
    items_info: null,
    items_loading: true,
    items_errored: false,
    itemcount: 0,

    currentBase: 0,
    baseItems: [{}],
    baseItemsChannels: [""],
    baseItemsChannelsDefault: [
      "-102,1;401,5;920,1;10008,3",
      "-102,1;920,3"
    ], 
    baseItemsChannelsDefaultCH: [
      "905,3;10006,3;305,2",
      ""
    ], 
    baseItemsChannelsDefaultZH: [
      "",
      "310,3;1,3"
    ],
    baseItemsArray: [],
    baseItemsArrayN: [],
    nBaseItems: [0],
    shortChannelList: 1,
    clientSystem: {},
    schweizbox: [-180, -90, 180, 90],
    ktzhbox: [8.354, 47.15, 8.996, 47.709],
    isLoggedIn: false, 

  },

  mixins: [myMixin],


  getters: {
    user(state) {
      return state.user;
    },
    isAuthenticated(state) {
      return state.idToken !== null;
    }
  },

  methods: {

    locationItemFromCoord(latlon) {

      let bb = {};
      bb.lat = latlon[0];
      bb.lon = latlon[1];
      bb.title = "";
      const from = turf.point([bb.lon, bb.lat]);
      const to = turf.point([this.$store.state.here.lon, this.$store.state.here.lat]);
      bb.dist = turf.distance(from, to, { units: "meters" });
      const config = {
        params: {
          lat: bb.lat,
          lon: bb.lon
        }
      };
      axios
        .get(this.state.serverRoot + `/address.php?`, config)
        .then(response => {
          bb.title = response.data;
        })
        .catch(error => {
          console.log(error);
        });

      return bb;
    },

  },

  mutations: {
    authUser(state, userData) {
      state.idToken = userData.token;
      state.userId = userData.userId;
    },
    storeUser(state, user) {
      state.user = user;
    },
    clearAuthData(state) {
      state.idToken = null;
      state.userId = null;
    },
    setLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },

    SET_TOPS(state, f) {
      if (f === null) {
        f = "";
      }
      state.topIds = f.split(",");
    },

    SET_MAP_TOPS(state, f) {
      if (f === null) {
        f = "";
      }
      state.topMapIds = f.split(",");
    },

    SET_HOMES(state, f) {
      if (f === null) {
        f = "";
      }
      let ff = f.split(",");
      state.homes = [];
      ff.forEach(function(homexy) {
        let h = homexy.split("/");
        h[0] = 1.0 * h[0];
        h[1] = 1.0 * h[1];
        state.homes.push(h);
      });
    },


    SET_MYLOCATIONS(state, f) {
      if (f === null) {
        f = "";
      }                     
      let a = f.split(",");
      let b = [];
      for (let i = 0; i < a.length; i++) {
        a[i] = a[i].split("/");
        a[i][0] = 1.0 * a[i][0];
        a[i][1] = 1.0 * a[i][1];

        let bb = {};
        let latlon = a[i];

        bb.lat = latlon[0];
        bb.lon = latlon[1];
        bb.title = "";
        const from = turf.point([bb.lon, bb.lat]);
        const to = turf.point([this.state.here.lon, this.state.here.lat]);
        bb.dist = turf.distance(from, to, { units: "meters" });
        // b[i] = this.locationItemFromCoord(a[i]);

        const config = {
          params: {
            lat: bb.lat,
            lon: bb.lon
          }
        };
        axios
          .get(this.state.serverRoot + `/addressmylocations.php?`, config)
          .then(response => {
            bb.title = response.data["addressshort"];
            bb.description = response.data["address"];
          })
          .catch(error => {
            console.log(error);
          });
        b[i] = bb;

        state.myLocationItems = b.sort((a, b) => {
          let val;
          if (a.dist === b.dist) {
            val = 0;
          } else {
            val = b.dist > a.dist ? -1 : 1;
          }
          return val;
        });
      }
      Vue.prototype.$cookies.set("mylocations", f);
    },


    /*         WRITE_TOPS(cookies, state) {
            cookies.set('tops', state.topIds.join(','));
        },
                
        WRITE_MAP_TOPS(cookies, state) {
            cookies.set('tops', state.topMapIds.join(','));
        },
 */
    VOTE_FAVS: function(paramsin) {
      const config = {
        params: {
          id: paramsin.channel,
          delta: paramsin.delta
        }
      };
      axios
        .get(this.state.serverRoot + `/server/votefavs.php`, config)
        /*         .then(response => {
          //console.log(response.data);
          //   commit("VOTE_FAVS_END");
        }) */
        .catch(error => {
          console.log(error);
        });
    },

    VOTE_MAP_FAVS: function(state, paramsin) {
      const config = {
        params: {
          id: paramsin.channel,
          delta: paramsin.delta,
          mapsites: 1
        }
      };
      axios
        .get(this.state.serverRoot + `/server/votefavs.php`, config)
        /*         .then(response => {
          //console.log(response.data);
          //      commit("VOTE_FAVS_END");
        }) */
        .catch(error => {
          console.log(error);
        });
    },

    // SET_FAVS(state, f) {
    //   if (typeof f === "undefined") {
    //     f = "905,3";
    //   }
    //   let favChannelTexts = f.split(",");
    //   state.favIds = favChannelTexts.filter((value, index) => index % 2 === 0);
    // },

    SET_AREAFILTER(state, how) {
      state.areaFilter = how;
    },

    SET_HERE(state, point) {
      
      state.here.lat = Number(Math.round(point.lat+'e'+6)+'e-'+6);
      state.here.lon = Number(Math.round(point.lon+'e'+6)+'e-'+6);
      // state.here.lat = point.lat;
      // state.here.lon = point.lon;

      if (state.areaFilter) {
        this.dispatch("LOAD_CHANNELS");
        this.dispatch("LOAD_CHANNELHITS");
      }
      const to = turf.point([state.here.lon, state.here.lat]);
      state.isHome = false;
      for (var i = 0; i < state.homes.length; i++) {
        let home = state.homes[i];
        const from = turf.point([home[1], home[0]]);
        let distance = turf.distance(from, to, {
          units: "meters"
        });
        //          console.log(distance);
        if (distance <= 300) {
          //            console.log("At Home");
          state.isHome = true;
        }
      }
      state.currentBase = state.isHome ? 1 : 0;
    },

    /*     SET_FAVCHANNELS(state) {
      state.favIds.forEach(favid => {
        const favC = state.channels.filter(elem => elem.ID === favid);
        state.favChannels.push(favC[0]);
      });
    }, */

    SET_SYSTEM(state, clientSystem) {
      state.clientSystem = clientSystem;
    },

    SET_CHANNELS(state, channels) {
      state.channels = channels;
    },


    SET_CHANNELHITS(state, channels) {
      state.channelHits = channels;
    },

    SET_CATEGORIES(state, categories) {
      state.categories = categories;
    },

    // BASE

    INIT_BASEITEMS(state, what) {
      const baseItemsString = what.baseItemsString;
      const nr = what.nr;
      // state.baseItemsChannels[nr] = baseItemsString;
      Vue.set(state.baseItemsChannels, nr, baseItemsString);
      if (typeof baseItemsString === "undefined") {
        return;
      }
      let bi2 = baseItemsString.split(";");
      let i;
      for (i = 0; i < bi2.length; i++) {
        if (bi2[i].includes("undefined")) {
          bi2.splice(i, 1);
        }
      }
      let baseItems = {};
      let ba = [],
        ban = [];
      i = 0;
      bi2.forEach(function(baseItem) {
        let bi = baseItem.split(",");
        let elem = {};
        elem.ID = bi[0];
        elem.n = bi[1];
        baseItems[bi[0]] = {
          ID: bi[0],
          n: bi[1],
          category: "Other"
        };
        ba[i] = bi[0];
        ban[i] = bi[1];
        i++;
      });
      Vue.set(state.baseItemsArray, nr, ba);
      Vue.set(state.baseItemsArrayN, nr, ban);
      Vue.set(state.baseItems, nr, baseItems);
      Vue.set(state.nBaseItems, nr, i);
    },

    SET_BASEITEM(state, bin) {
      //  console.log("SET_BASEITEM " + bin.n + " " + JSON.stringify(bin.baseitem));
      for (const [bkey, oneitem] of Object.entries(bin.baseitem)) {
        for (const [kb, value] of Object.entries(oneitem)) {
          Vue.set(state.baseItems[bin.n][bkey], kb, value);
        }
        //let catego = getChannelCategory(bkey);
        //  Vue.set(state.baseItems[bin.n][bkey], "category", "catego");
      }
      state.baseLoaded++;
    },

    ADD_BASEITEMS_CHANNELINFO(state, nr) {
      let key;
      for (key in state.baseItems[nr]) {
        //   if (state.baseItems.hasOwnProperty(key)) {
        if (key < 0) {
          state.negChannels.forEach(function searchid(channel) {
            if (key === channel.ID) {
              Vue.set(state.baseItems[nr][key], "channel", channel.title);
              Vue.set(state.baseItems[nr][key], "category", "Other");
            }
          });
        } else {
          state.channels.forEach(function searchid(channel) {
            if (key === channel.ID) {
              Vue.set(state.baseItems[nr][key], "channel", channel.title);
              Vue.set(state.baseItems[nr][key], "category", channel.category);
            }
          });
        }
        //   }
      }
    },

    updateList(state, baseItemsArray) {
      let nr = state.currentBase;
      Vue.set(state.baseItemsArray, nr, baseItemsArray);
      let baseItemsArrayN = [];
      for (let i = 0; i < baseItemsArray.length; i += 1) {
        for (let j = 0; j < state.baseItemsArray[nr].length; j += 1) {
          if (state.baseItemsArray[nr][i] == baseItemsArray[i]) {
            baseItemsArrayN.push(state.baseItemsArrayN[nr][i]);
            continue;
          }
        }
      }
      Vue.set(state.baseItemsArrayN, nr, baseItemsArrayN);
      let str = "";
      for (let i = 0; i < state.baseItemsArray[nr].length; i += 1) {
        str += state.baseItemsArray[nr][i];
        str += ",";
        str += state.baseItemsArrayN[nr][i];
        str += ";";
      }
      str = str.slice(0, -1);
      Vue.prototype.$cookies.set("base_" + nr, str);
    },

    updateMyLocationCookie(state) {
      let ml = state.myLocationItems.map(loc => loc.lat + "/" + loc.lon).join(",");
      Vue.prototype.$cookies.set("mylocations", ml);
    },

    updateHomes(state) {
      let m = [];
      for (let i = 0; i < state.homes.length; i += 1) {
        m[i] = state.homes[i][0] + "/" + state.homes[i][1];
      }
      m = m.join(",");
      Vue.prototype.$cookies.set("homes", m);
    },


    /*     SET_BASEITEMS(state, baseItems) {
      //     state.baseItems = baseItems;
      for (let key in baseItems) {
        Vue.state(state.baseItems[state.currentBase], key, baseItems[key]);
      }
      state.baseLoaded++;
    },
 */
    // END BASEITEMS

    STORE_COOKIES(state, nr) {
      let str = "";
      for (let i = 0; i < state.baseItemsArray[nr].length; i += 1) {
        str += state.baseItemsArray[nr][i];
        str += ",";
        // str += state.baseItemsArrayN[i];
        str += "3";
        str += ";";
      }
      str = str.slice(0, -1);
      // console.log("STORE_COOKIES " + str);
      Vue.prototype.$cookies.set("base_" + nr, str);
      Vue.prototype.$cookies.set("curtab", state.currentBase);
    },

    STORE_HOMES(state) {
      let m = [];
      state.homes.forEach((home) =>  m.push(home[0] + "/" + home[1]));
      m = m.join(",");
      Vue.prototype.$cookies.set("homes", m);
    },

    SET_NEWS(state, news) {
      state.news = news;
    },

    SET_ITEMS(state, items) {
      state.items = items;
      state.baseLoaded++;
      state.itemsLoaded = true;
    },
    

    SET_ITEMS_MORE(state, items) {
      // console.log(items);
      // if (items.lenth == 0) {
      //     state.complete();
      // } else {
      //     state.loaded();
      // }
      state.items.push(...items);
      state.itemsLoaded = true;
    },

    SET_FAVITEMS(state, favItems) {
      state.favItems = favItems;
    },

    SET_MAPITEMS(state, mapItems) {
      state.mapItems = mapItems;
    },

    SET_SWISSTOPOITEMS(state, swisstopoItems) {
      state.swisstopoItems = swisstopoItems;
    },

    SET_FAVLOCATIONS(state, swisstopoItems) {
      state.favLocations = swisstopoItems;
    },

    SET_ADDRESS(state, address) {
      state.address = address;
    },

    SET_ALTITUDE(state, altitude) {
      state.here.altitude = altitude;
    },

    SET_ITEM_COUNT(state, itemcount) {
      state.itemcount = itemcount;
    },

    SET_ACTIVECHANNEL(state, activeChannel) {
      state.activeChannel = activeChannel;
    },

    SET_MAPCHANNEL(state, mapChannel) {
      state.mapChannel = mapChannel;
    },

    SET_SWISSTOPOCHANNEL(state, swisstopoChannel) {
      state.swisstopoChannel = swisstopoChannel;
    },

    defineServerRoot(state, url) {
      state.serverRoot = url;
    },

    SET_ITEMSLOADING_STATUS(state, loadingstates) {
      state.items_loading = loadingstates.loading;
      state.items_info = loadingstates.info;
      state.items_errored = loadingstates.errored;
      state.items_errormsg = loadingstates.errormsg;
    },

  },

  actions: {
    setLogoutTimer({ commit }, expirationTime) {
      setTimeout(() => {
        commit("clearAuthData");
      }, expirationTime * 1000);
    },

    signup({ commit, dispatch }, authData) {
      console.log(this.state.serverRoot);
      console.log(this.state.serverRoot);
      console.log(this.state.serverRoot);
    userAxios
 
  
   .post(this.state.serverRoot + "/server/loginsystem/register.php", {
    email: authData.email,
          password: authData.password,
          returnSecureToken: true
        })
        .then(res => {
          console.log(res);
          commit("authUser", {
            token: res.data.idToken,
            userId: res.data.localId
          });
          const now = new Date();
          const expirationDate = new Date(
            now.getTime() + res.data.expiresIn * 1000
          );
          localStorage.setItem("token", res.data.idToken);
          localStorage.setItem("userId", res.data.localId);
          localStorage.setItem("expirationDate", expirationDate);
          dispatch("storeUser", authData);
          dispatch("setLogoutTimer", res.data.expiresIn);
        })
        .catch(error => {
          console.log("*** error at signup");
          console.log(error);
        });
    },
    login({ commit, dispatch }, authData) {
      console.log("login");

      userAxios
      // .post("/verifyPassword?key=AIzaSyC0ZSufuAMXOmw76CKNaWLLcnGLTy8D7gg", {
        .post(this.state.serverRoot + "/loginsystem/login.php?", {
          email: authData.email,
          password: authData.password,
          returnSecureToken: true
        })
        .then(res => {
          console.log("res");
          console.log(res);
          const now = new Date();
          const expirationDate = new Date(
            now.getTime() + res.data.expiresIn * 1000
          );
          localStorage.setItem("token", res.data.idToken);
          localStorage.setItem("userId", res.data.localId);
          localStorage.setItem("expirationDate", expirationDate);
          commit("authUser", {
            token: res.data.idToken,
            userId: res.data.localId
          });
          dispatch("setLogoutTimer", res.data.expiresIn);
          console.log(routes);
          routes.push({
            path: "/"
          });
          // routes.replace("/");
          // routes.push({  path: '/', component: TheInputList});
          //this.$router.push('/');
        })
        .catch(error => {
          console.log(error);
          if (typeof error.response !== "undefined") {
            console.log(error.response);
            console.log(
              "*** ERROR " +
                authData.email +
                ": " +
                error.response.data.error.message
            );
          }
        });
    },

    tryAutoLogin({ commit }) {
      const token = localStorage.getItem("token");
      if (!token) {
        return;
      }
      const expirationDate = localStorage.getItem("expirationDate");
      const now = new Date();
      if (now >= expirationDate) {
        return;
      }
      const userId = localStorage.getItem("userId");
      commit("authUser", {
        token: token,
        userId: userId
      });
    },
    logout({ commit }) {
      commit("clearAuthData");
      localStorage.removeItem("expirationDate");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");
      localStorage.removeItem("jwt");
    },
    storeUser({ state }, userData) {
      if (!state.idToken) {
        return;
      }
      axios
        .post("/users.json" + "?auth=" + state.idToken, userData)
        .then(res => console.log(res))
        .catch(error => console.log(error));
    },
    fetchUser({ commit, state }) {
      if (!state.idToken) {
        return;
      }
      axios
        .get("/users.json" + "?auth=" + state.idToken)
        .then(res => {
          console.log(res);
          const data = res.data;
          const users = [];
          for (let key in data) {
            const user = data[key];
            user.id = key;
            users.push(user);
          }
          console.log(users);
          commit("storeUser", users[0]);
        })
        .catch(error => console.log(error));
    },
    
    // isIn: function(lat, lon, box) {
    //   console.log(lat, lon);
    //   console.log(box);
    //   return (lon > box[0] && lat > box[1] && lon < box[2] && lat < box[3]);
    // },

    LOAD_MAP_TOPS: function({ commit }) {
      let maptopsin = Vue.prototype.$cookies.get("maptops");
      if (maptopsin === null) {
        maptopsin = this.state.mapTopDefault;

        let lat = this.state.here.lat;
        let lon = this.state.here.lon;
        
        let box = this.state.schweizbox;
        if (lon > box[0] && lat > box[1] && lon < box[2] && lat < box[3]) {
          maptopsin += "," + this.state.mapTopDefaultCH;
        }  

        box = this.state.ktzhbox;
  //      if (this.isIn(this.state.here.lat, this.state.here.lon, this.state.mapTopDefaultZH)) {
        if (lon > box[0] && lat > box[1] && lon < box[2] && lat < box[3]) {
          maptopsin += "," + this.state.mapTopDefaultZH;
        }
      }
      commit("SET_MAP_TOPS", maptopsin);
    },

    LOAD_TOPS: function({ commit }) {
      commit("SET_TOPS", this.$cookies.get("tops"));
    },

    SAVE_TOPS: function({ commit }) {
      commit("WRITE_TOPS");
    },

    LOAD_CHANNELS: function({ commit }) {
      const config = {
        params: {
          lat: this.state.here.lat,
          lon: this.state.here.lon,
          covered: this.state.areaFilter
        }
      };
      var catego = new Set();
      axios
        .get(this.state.serverRoot + `/getchannels.php?`, config)
        .then(response => {
          let channels = response.data;
          for (let i = 0; i < channels.length; i += 1) {
            channels[i].count = parseInt(channels[i].count);
            catego.add(channels[i].category);
          }
          let categoarray = Array.from(catego);
          commit("SET_CHANNELS", channels);
          commit("SET_CATEGORIES", categoarray);
          // commit("SET_FAVCHANNELS");
          commit("ADD_BASEITEMS_CHANNELINFO");
        })
        .catch(error => {
          console.log(`ERROR: ${error}`);
        });
    },

    LOAD_CHANNELHITS: function({ commit }) {
      const config = {
        params: {
          lat: this.state.here.lat,
          lon: this.state.here.lon,
          covered: this.state.areaFilter
        }
      };
     // var catego = new Set();

      axios
      .get(this.state.serverRoot + `/getchannelhits.php?`, config)
      .then(response => {
          let channels = response.data;
          for (let i = 0; i < channels.length; i += 1) {
            channels[i].count = parseInt(channels[i].count);
            // catego.add(channels[i].category);
          }
          commit("SET_CHANNELHITS", channels);
         // commit("SET_CATEGORIES", categoarray);
          // commit("SET_FAVCHANNELS");
         // commit("ADD_BASEITEMS_CHANNELINFO");
        })
        .catch(error => {
          console.log(`ERROR: ${error}`);
          this.state.serverOk = false;
          this.state.serverError = error;
        });
    },

    LOAD_BASEITEMS: function({ commit }) {
      for (let n = 0; n < this.state.baseItemsArray.length; n += 1) {
        for (let i = 0; i < this.state.baseItemsArray[n].length; i += 1) {
          const config = {
            params: {
              lat: this.state.here.lat,
              lon: this.state.here.lon,
              id:
                this.state.baseItemsArray[n][i] +
                "," +
                this.state.baseItemsArrayN[n][i]
            }
          };
          //console.log("loading " + JSON.stringify(config));
          axios
            .get(this.state.serverRoot + `/server/1value/index.php?`, config)
            .then(response => {
              let baseitem = response.data;
              commit("SET_BASEITEM", {
                baseitem: baseitem,
                n: n
              });
              //commit("ADD_BASEITEMS_CHANNELINFO");
            })
            .catch(error => {
              console.log(`ERROR: ${error}`);
            });
        }
      }
      // if (1 < 0) {

      // const config = {
      //   params: {
      //     lat: this.state.here.lat,
      //     lon: this.state.here.lon,
      //     id: this.state.baseItemsChannels
      //   }
      // };
      // axios
      //   .get(this.state.serverRoot + `/server/1value/index.php?`, config)
      //   .then(response => {
      //     let baseitems = response.data;
      //     console.log(baseitems);
      //     commit("SET_BASEITEMS", baseitems);
      //     commit("ADD_BASEITEMS_CHANNELINFO");
      //   })
      //   .catch(error => {
      //     console.log(`ERROR: ${error}`);
      //   });
      // }
    },

    LOAD_ITEMS: function({ commit }, channelid) {
      // this.state.items = [];
      this.state.waitingForItems = true;
      const config = {
        params: {
          id: channelid,
          lat: this.state.here.lat,
          lon: this.state.here.lon
        }
      };

      function sortItems(items0, loncenter, latcenter) {
        let items = items0;
        if (Array.isArray(items) && items.length) {
          if (!("dist" in items[0])) {
            const from = turf.point([loncenter, latcenter]);
            items.map(item => {
              let dist = 0;
              if (item.lat !== null) {
                const to = turf.point([item.lon, item.lat]);
                dist = turf.distance(from, to, {
                  units: "meters"
                });
              } else {
                dist = 99;
              }
              const itemnew = item;
              itemnew.dist = dist;
              return itemnew;
            });
          } else {
            /* eslint-disable no-param-reassign */
            items.map(item => {
              item.dist *= 1.0;
              return item;
            });
            /* eslint-enable no-param-reassign */
          }
          items = items.sort((a, b) => {
            let val;
            if (a.dist === b.dist) {
              val = 0;
            } else {
              val = b.dist > a.dist ? -1 : 1;
            }
            return val;
          });
        }
        items.forEach( item => item.lat = 1 * item.lat);
        items.forEach( item => item.lon = 1 * item.lon);
        return items;
      }
      // commit("SET_ITEMS", []);

      const loadingstatus = {
        loading: true,
        info: false,
        errored: false,
        errormsg: ""
      };
      commit("SET_ITEMSLOADING_STATUS", loadingstatus);
      axios
        .get(this.state.serverRoot + `/server/get3.php?`, config)
        .then(response => {
          if (typeof response.data.items != "undefined") {
            let items = sortItems(
              response.data.items,
              response.config.params.lon,
              response.config.params.lat
            );
            if (channelid == 204) {
              commit("SET_MAPITEMS", items);
            } else if (channelid == 304) {
              commit("SET_SWISSTOPOITEMS", items);
            } else if (channelid == 7) {
              commit("SET_FAVLOCATIONS", items);
            } else {
              items[0].sameLocationAsPrevious = false;
              for (let i = 1; i < items.length; i += 1) {
                let same = items[i].lat === items[i-1].lat && items[i].lon === items[i-1].lon;
                items[i].sameLocationAsPrevious = same;
              }
              commit("SET_ITEMS", items);
            }
          }
          const loadingstatus = {
            loading: false,
            info: true,
            errored: false,
            errormsg: ""
          };
          commit("SET_ITEMSLOADING_STATUS", loadingstatus);
        })
        .catch(error => {
          console.log(`ERROR: ${error}`);
          const loadingstatus = {
            loading: false,
            info: false,
            errored: true,
            errormsg: error
          };
          commit("SET_ITEMSLOADING_STATUS", loadingstatus);
        })
        .finally(() => (this.state.items_loading = false));
      /*                     this.get1Channel(channelid);
                                this.activeTab = channelid === 204 ? 3 : 1;
             */
    },

    LOAD_ITEMS_MORE: function({ commit }, configin) {
      // channelid, offset, state
      this.state.waitingForItems = true;
      const config = {
        params: {
          id: configin.channel,
          lat: this.state.here.lat,
          lon: this.state.here.lon,
          offset: configin.offset
        }
      };

      function sortItems(items0, loncenter, latcenter) {
        let items = items0;
        if (Array.isArray(items) && items.length) {
          if (!("dist" in items[0])) {
            const from = turf.point([loncenter, latcenter]);
            items.map(item => {
              let dist = 0;
              if (item.lat !== null) {
                const to = turf.point([item.lon, item.lat]);
                dist = turf.distance(from, to, {
                  units: "meters"
                });
              } else {
                dist = 99;
              }
              const itemnew = item;
              itemnew.dist = dist;
              return itemnew;
            });
          } else {
            /* eslint-disable no-param-reassign */
            items.map(item => {
              item.dist *= 1.0;
              return item;
            });
            /* eslint-enable no-param-reassign */
          }
          items = items.sort((a, b) => {
            let val;
            if (a.dist === b.dist) {
              val = 0;
            } else {
              val = b.dist > a.dist ? -1 : 1;
            }
            return val;
          });
        }

        return items;
      }
      axios
        .get(this.state.serverRoot + `/server/get3.php?`, config)
        .then(response => {
          let items = sortItems(
            response.data.items,
            response.config.params.lon,
            response.config.params.lat
          );
          if (items.length <= 0) {
            configin.infiniteLoaderContext.complete();
          } else {
            commit("SET_ITEMS_MORE", items);
            configin.infiniteLoaderContext.loaded();
          }
        })
        .catch(error => {
          console.log(`ERROR: ${error}`);
        });
    },

    LOAD_CHANNEL: function({ commit }, id) {
      const config = {
        params: {
          id
        }
      };
      axios
        .get(this.state.serverRoot + `/get1channel.php`, config)
        .then(response => {
          let activeChannel = response.data[0];
          commit("SET_ACTIVECHANNEL", activeChannel);
        })
        .catch(error => {
          console.log(error);
        });
    },

    LOAD_NEWS: function({ commit }) {
      const config = {
        params: {}
      };
      axios
        .get(this.state.serverRoot + `/server/news.php`, config)
        .then(response => {
          let news = response.data;
          commit("SET_NEWS", news);
        })
        .catch(error => {
          console.log(error);
        });
    },

    LOAD_ADDRESS: function({ commit }) {
      const config = {
        params: {
          lat: this.state.here.lat,
          lon: this.state.here.lon
        }
      };
      axios
        .get(this.state.serverRoot + `/address.php?`, config)
        .then(response => {
          let address = response.data;
          commit("SET_ADDRESS", address);
        })
        .catch(error => {
          console.log(error);
        });
    },

    LOAD_ALTITUDE: function({ commit }) {
      const config = {
        params: {
          lat: this.state.here.lat,
          lon: this.state.here.lon
        }
      };
      axios
        .get(this.state.serverRoot + `/server/utils/getaltitude.php?`, config)
        .then(response => {
          let altitude = response.data.results[0].elevation;
          // console.log("Altitude: " + altitude);
          commit("SET_ALTITUDE", altitude);
        })
        .catch(error => {
          console.log(error);
        });
    },


    LOAD_HOMES: function({ commit }) {
      let homesin = Vue.prototype.$cookies.get("homes");
      if (!homesin) {
        homesin = this.state.homesDefault;
      }
      commit("SET_HOMES", homesin);
    },

    LOAD_MYLOCATIONS: function({ commit }) {
      let myLoc = Vue.prototype.$cookies.get("mylocations");
      if (myLoc === null || myLoc === "") {
        myLoc = this.state.myLocationsDefault;
      }
      commit("SET_MYLOCATIONS", myLoc);
    },
 
    COUNT_ITEMS: function({ commit }, id) {
      const config = {
        params: {
          id
        }
      };
      axios
        .get(this.state.serverRoot + `/itemscount.php?`, config)
        .then(response => {
          let itemscount = response.data;
          commit("SET_ITEM_COUNT", itemscount);
        })
        .catch(error => {
          console.log(error);
        });
    },

    LOAD_FAVITEMS: function({ commit }, f) {
      function getAndAppendItemsFrom(
        rank,
        channelid,
        params,
        state,
        myFavItems,
        commit
      ) {
        const config = {
          params: {
            id: channelid,
            lat: state.here.lat,
            lon: state.here.lon
          }
        };
        const p = params.split("$");
        const nitems = p[0];
        if (p.length > 1) {
          const pp = p[1].split("&");
          pp.forEach(ppp => {
            const pppp = ppp.split("=");
            // eslint-disable-next-line prefer-destructuring
            config.params[pppp[0]] = pppp[1];
          });
        }

        function sortItems(items0, loncenter, latcenter) {
          let items = items0;
          if (Array.isArray(items) && items.length) {
            if (!("dist" in items[0])) {
              // console.log('*** distances in items missing');
              const from = turf.point([loncenter, latcenter]);
              items.map(item => {
                let dist = 0;
                if (item.lat !== null) {
                  const to = turf.point([item.lon, item.lat]);
                  dist = turf.distance(from, to, {
                    units: "meters"
                  });
                } else {
                  dist = 99;
                }
                const itemnew = item;
                itemnew.dist = dist;
                return itemnew;
              });
            } else {
              console.log(
                `*** distances in items present, e.g. ${items[0].dist}`
              );
              /* eslint-disable no-param-reassign */
              items.map(item => {
                item.dist *= 1.0;
                return item;
              });
              /* eslint-enable no-param-reassign */
            }
            items = items.sort((a, b) => {
              let val;
              if (a.dist === b.dist) {
                val = 0;
              } else {
                val = b.dist > a.dist ? -1 : 1;
              }
              return val;
            });
          }
          return items;
        }
        console.log("state.serverRoot");
        console.log(state.serverRoot);

        axios
          .get(state.serverRoot + `/server/get3.php`, config)
          .then(response => {
            let favChannel = response.data.channel;
            favChannel.tech = `*${favChannel.tech}`;
            const iii = sortItems(
              response.data.items,
              response.config.params.lon,
              response.config.params.lat
            ).slice(0, nitems);
            for (let index = 0; index < iii.length; index += 1) {
              iii[index].footer = favChannel.title;
            }
            myFavItems[rank] = iii;
            let favItemsFlat = [].concat(...myFavItems);
            favItemsFlat = favItemsFlat.filter(n => n !== undefined);

            myFavItems = myFavItems.concat(iii);
            myFavItems = favItemsFlat;
            //this.get2Channel(channelid);
            commit("SET_FAVITEMS", favItemsFlat);
          })
          .catch(error => {
            console.log(error);
          });
      }

      this.favChannelTexts = f.split(",");
      this.favIds = this.favChannelTexts.filter(
        (value, index) => index % 2 === 0
      );
      let myFavItems = [];
      for (let i = 0; i < this.favChannelTexts.length; i += 2) {
        getAndAppendItemsFrom(
          i / 2,
          this.favChannelTexts[i],
          this.favChannelTexts[i + 1],
          this.state,
          myFavItems,
          commit
        );
      }
    }
  }
});
