<template>
  <div>
    <b-jumbotron header-level="4">
      <template #header>
        UmMiUme
      </template>

      <template #lead>
        Location based information.
      </template>

      <hr class="my-4">
      <b-list-group>
        <b-list-group-item
          href="https://umu.mapresso.com"
          target="_blank_"
        >
          The project
        </b-list-group-item>
        <b-list-group-item
          href="https://umu.mapresso.com/?docs=client"
          target="_blank_"
        >
          Help
        </b-list-group-item>
        <b-list-group-item
          href="https://umu.mapresso.com/?page_id=184"
          target="_blank_"
        >
          Contact form / Newsletter
        </b-list-group-item>
      </b-list-group>

  
      <!-- Information around a location from various sources in different formats (GeoJSON, GeoRSS,
        Geo Feed (Atom), API, CSV, Web Scraping, JSON, XML, etc.). -->
      <p />

      <b-button
        variant="primary"
        href="mailto:ummiume@mapresso.com?subject=UmMiUmeFeedback"
      >
        Mail feedback
      </b-button>
      <br><br><small>&copy; Adrian Herzog, 2020-{{ currentYear() }}</small>
      <br><br><b>Um mi ume </b>(Swiss German) <br>Um mich herum (German) <br>Around me (English)
      <hr>
      <UmuNews /> 


        

      <!--  Some icons by <a
        target="_blank"
        href="https://icons8.com"
      >Icons8</a>      
           <b-img
        title="Temperature Change in Switzerland 1864-2019r"
        alt="Temp. change Switzerland"
        src="https://ummiume.mapresso.com/images/_stripes_EUROPE-Switzerland--1864-2019-MS.png"
        width="300"
      />
      <br><br> 
      <b-img
        title="A Generative Placeholder"
        alt="placeholder"
        src="https://generative-placeholders.glitch.me/image?width=300&height=300&style=circles&gap=5&img=" 
      /> -->
    </b-jumbotron>
    
    <div class="table-responsive-sm">
      <table class="b-table table table-striped bv-docs-table">
        <tr>
          <th
            valign="top"
            align="left"
            style="font-size:80%"
          >
            OS
          </th><td
            valign="top"
            align="left"
            style="font-size:80%"
          >
            {{ this.$store.state.clientSystem.os }} {{ this.$store.state.clientSystem.osVersion }}
          </td>
        </tr>
        <tr>
          <th
            valign="top"
            align="left"
            style="font-size:80%"
          >
            Browser
          </th><td
            valign="top"
            align="left"
            style="font-size:80%"
          >
            {{ this.$store.state.clientSystem.browser }} {{ this.$store.state.clientSystem.browserMajorVersion }} 
            ({{ this.$store.state.clientSystem.browserVersion }})
          </td>
        </tr>
        <tr>
          <th
            valign="top"
            align="left"
            style="font-size:80%"
          >
            Mobile
          </th><td
            valign="top"
            align="left"
            style="font-size:80%"
          >
            {{ this.$store.state.clientSystem.mobile }}
          </td>
        </tr>
        <tr>
          <th
            valign="top"
            align="left"
            style="font-size:80%"
          >
            Cookies
          </th><td
            valign="top"
            align="left"
            style="font-size:80%"
          >
            {{ this.$store.state.clientSystem.cookies }}
          </td>
        </tr>
        <tr>
          <th
            valign="top"
            align="left"
            style="font-size:80%"
          >
            Screen Size
          </th><td
            valign="top"
            align="left"
            style="font-size:80%"
          >
            {{ this.$store.state.clientSystem.screen }}
          </td>
        </tr>
        <tr>
          <th
            valign="top"
            align="left"
            style="font-size:80%"
          >
            Full User Agent
          </th>
          <td
            valign="top"
            align="left"
            style="font-size:80%"
          >
            {{ this.$store.state.clientSystem.fullUserAgent }}
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
  
import UmuNews from '@/components/UmuNews.vue';

export default {
  name: 'About',

  components: {
      UmuNews,
  },
   methods: {
    currentYear() {
      return new Date().getFullYear();
    },
   }
};
</script>

<style>
jumbotron.display-3 {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.2;
}
</style>