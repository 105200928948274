/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-left': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" _fill="param(fill) #000" opacity="param(fill-opacity)" _stroke="param(outline)" stroke-width="param(outline-width) 0" stroke-opacity="param(outline-opacity)" d="M20 11v2H8l5.5 5.5-1.42 1.42L4.16 12l7.92-7.92L13.5 5.5 8 11h12z"/>'
  }
})
