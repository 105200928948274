<template>
  <div>
    <h3>
      <span @click="switchBaseItem(activeChannel.ID, $event)">
        <span
          v-if="isBase(activeChannel.ID)"
          style="padding-right: 9px"
        >
          <b-icon-star-fill
            font-scale="0.75"
            style="margin-bottom: 3px"
          />
        </span>
        <span
          v-else
          style="padding-right: 9px"
        >
          <b-icon-star
            font-scale="0.75"
            style="margin-bottom: 3px"
          />
        </span>
      </span>
      {{ activeChannel.title }}
    </h3>
    <div style="font-size: 14px; line-height: 1.2; padding-bottom: 9px">
      {{ activeChannel.subtitle }}
    </div>

    <b-tabs
      content-class="mt-3"
      @activate-tab="doEvent('activate-tab')"
      @changed="doEvent('changed')"
      @input="doEvent('input')"
      v-model="tabIndex"
    >
      <b-tab title="Results">
        <section v-if="this.items_errored">
          <b-alert
            variant="danger"
            show
          >
            We're sorry, we're not able to retrieve this information at the
            moment, please try back later.<br>
            <small>{{ itemsErrorMsg }}</small>
          </b-alert>
        </section>

        <section v-else>
          <div v-if="this.items_loading">
            <br><br><br><br><br><br>
            <center>
              <circle4 />
            </center>
            <br>
            Loading...

            <!--vue-simple-spinner size="small" message="Loading..."></vue-simple-spinner> -->
          </div>
          <div v-else-if="items.length == 0">
            <br>
            Nothing found.
          </div>
          <div v-else>
            <div
              v-if="activeChannel.legend != ''"
              class="legendtext"
              v-html="activeChannel.legend"
            >
              {{ this.activeChannel.legend }}
            </div>
            <div
              class="row"
              v-if="items.length > 3"
            >
              <div class="col-md-6">
                <input
                  type="text"
                  class="form-control"
                  id="myFilter"
                  @keyup="cardFilterFunction('myItems', 'myFilter')"
                  placeholder="Filter results ..."
                >
              </div>
            </div>
            <!-- ITEMS records -->
            <div
              class="row"
              id="myItems"
            >
              <div class="col-md-12">
                <div
                  v-for="(item, index) in items"
                  :key="item.ID"
                >
                  <item-card
                    :item="item"
                    :nr="index"
                    :herelatitude="hlat"
                    :herelongitude="hlon"
                    :channel="activeChannel"
                    :server-root="serverRoot"
                    :isfavlocations="0"
                  />
                </div>
              </div>
            </div>
            <div v-if="activeChannel.more > 0">
              <infinite-loading
                @infinite="infiniteHandler"
                spinner="bubbles"
              >
                <div slot="no-more">
                  Keine weiteren Ergebnisse vorhanden
                </div>
                <div slot="no-results">
                  <br>Keine weiteren Ergebnisse
                </div>
              </infinite-loading>
            </div>
            <br><br>
          </div>
        </section>
      </b-tab>
      <b-tab
        title="Map"
        @shown="doEvent('shown')"
        @click="doEvent('click')"
        :disabled="withMap()"
      >
        <div
          v-if="activeChannel.mapdisplay > -1000"
          style="500px"
        >
          <!-- MAP -->
          <l-map
            :zoom="currentZoom"
            :center="currentCenter"
            :bounds="markerBounds"
            :options="mapOptions"
            style="height: 250px; width: 100%"
            @update:center="centerUpdate"
            @click="setCenter"
            @update:zoom="zoomUpdate"
            @update:bounds="boundUpdate"
            @ready="doEvent('ready')"
            ref="map_items"
          >
            <l-tile-layer
              :url="url"
              :attribution="attribution"
            />
            <l-marker
              :lat-lng="hereCenter()"
              :icon="redIcon"
            >
              <l-tooltip content="Analysis Location" />
            </l-marker>
            <l-marker
              v-for="(marker, index) in markers"
              :key="index"
              :lat-lng="marker"
              :icon="markerIcon[index]"
              @click="innerClick(index)"
            >
              <l-tooltip :content="tooltips[index]" />
            </l-marker>
          </l-map>
          <br>
        </div>
        <div>
          <div
            v-for="(item, index) in items"
            :key="item.ID"
          >
            <div v-if="markerClickedIndex == index">
              <item-card
                :item="item"
                :nr="index"
                :herelatitude="hlat"
                :herelongitude="hlon"
                :channel="activeChannel"
                :server-root="serverRoot"
                :isfavlocations="0"
              />
            </div>
          </div>
        </div>
      </b-tab>
      <b-tab
        title="Metadata"
        @click="getCountItems(activeChannel.ID)"
      >
        <b-card>
          <h4>Description</h4>
          <p
            style="text-align: LEFT"
            v-html="activeChannel.description"
          />
          <br>

          <h4>Data Source</h4>
          <span v-html="channelLink()" /> &nbsp;
          <b-icon-box-arrow-up-right font-scale="0.8" /><br><br>

          <h4>Age of data</h4>
          <span
            v-html="channelLastUpdate(activeChannel.lastBuildDate)"
          /><br><br>

          <h4>Spatial Coverage</h4>
          <span v-html="activeChannel.coverage" /><br><br>

          <h4>Tech</h4>
          <span v-html="activeChannel.tech" /><br>
          Code: <span v-html="activeChannel.linkintern" /><br><br>

          <h4>Raw Items</h4>
          <a
            :href="
              serverRoot +
                '/server/get3.php?id=' +
                activeChannel.ID +
                '&amp;lat=' +
                this.hlat +
                '&amp;lon=' +
                this.hlon
            "
            :title="activeChannel.linktext"
            target="_blank"
          >
            Show Raw Items (GeoRSS as JSON): &nbsp;
            <b-icon-box-arrow-up-right /> </a><br>
          <a
            :href="
              serverRoot +
                '/server/xml.php?url=https://ummiume.mapresso.com/backend/server/get3.php?&id=' +
                activeChannel.ID +
                '&amp;lat=' +
                this.hlat +
                '&amp;lon=' +
                this.hlon
            "
            :title="activeChannel.linktext"
            target="_blank"
          >
            Show Raw Items as GeoRSS (XML): &nbsp;
            <b-icon-box-arrow-up-right />
          </a>
          <br><br>

          <h4>Items of this channel in local database</h4>
          <span v-if="this.$store.state.itemcount == 0">Channel not cached</span><span
            v-else
            v-html="this.$store.state.itemcount"
          /> <br>
          <span v-if="this.$store.state.itemcount > 0">

            last built: {{ activeChannel.lastBuildDate }} ({{ this.getAge(activeChannel.lastBuildDate) }})</span><br><br>

          <div v-if="activeChannel.cacheable > 0">
            <h4>Channel Administration</h4>
            <!--             <span><a 
              :href="
                serverRoot +
                  '/server/get.php?dbfeed=1&id=' + activeChannel.ID"
              target="_blank"
            >GET</a> new items</span>
            <br>

            <span><a 
              :href="
                serverRoot +
                  '/server/deleteitems.php?id=' + activeChannel.ID"
              target="_blank"
            >DELETE</a> cached items</span> -->
            <span class="disabled">GET new items<br>DELETE new items</span>
            <br><br>
          </div>
          <hr>
        </b-card>
        <b-card class="testexplain">
          <h3>Debug</h3>

          <br>
          <h4>Channel Info</h4>
          <br>
          <span v-html="getMeta1Channel(activeChannel)" />
        </b-card>
        <br><br>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script src="https://unpkg.com/leaflet@1.2.0/dist/leaflet.js">
</script>

<script src="js/leaflet.extra-markers.js">
</script>

<script>
import moment from "moment";
import { latLng } from "leaflet";
import ItemCard from "@/components/ItemCard.vue";
import "leaflet/dist/leaflet.css";
import { mapState } from "vuex";
import myMixin from "@/myMixin.js";
import InfiniteLoading from "vue-infinite-loading";
import { debounce } from "vue-debounce";
import { Circle4 } from "vue-loading-spinner";

var dayjs = require('dayjs');
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime);

export default {
  name: "TheItemsList",

  mixins: [myMixin],

  metaInfo() {
    const descr = "Items around the current location.";
    return {
      title: "Resulting Items",
      meta: [
        { name: "description", content: descr },
      ]
    };
  },

  components: {
    ItemCard,
    InfiniteLoading,
    Circle4,
  },

  computed: {
    ...mapState([
      "here",
      "items",
      "activeChannel",
      "serverRoot",
      "items_loading",
      "items_errored",
    ]),

    hlat() {
      return 1 * this.$store.state.here.lat;
    },

    hlon() {
      return 1 * this.$store.state.here.lon;
    },

    hasItems() {
      return this.$store.state.items.length > 0;
    },

    fma() {
      return this.$store.state.items.length;
    },

    itemsErrorMsg() {
      return this.$store.state.items_errormsg;
    },


    // itemsLoading() {
    //     this.doLog("itemsLoading");
    //     return this.$store.state.items_loading;
    // },
    // itemsErrored() {
    //     this.doLog("itemsErrored");
    //     return this.$store.state.items_errored;
    // },
  },

  watch: {
    items: function () {
      //     this.doLog("watch");
      this.buildMarkers();
    },
    markerBounds: "setBoundsWatch",
  },

  data() {
    return {
      tabIndex: 0,
      zoom: 13,
      numberIcon: L.divIcon({
        className: "number-icon",
        iconSize: [25, 41],
        iconAnchor: [10, 44],
        popupAnchor: [3, -40],
        html: "23",
        markerIcon: [],
      }),

      defaultIcon: L.icon({
        iconUrl: "http://leafletjs.com/examples/custom-icons/leaf-red.png",
        shadowUrl: "http://leafletjs.com/examples/custom-icons/leaf-shadow.png",
        iconSize: [38, 95],
        shadowSize: [50, 64],
        iconAnchor: [22, 94],
        shadowAnchor: [4, 62],
        popupAnchor: [-3, -76],
        // backgroundColor: '#FF0000',
      }),

      miniIcon: new L.Icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [12, 20],
        iconAnchor: [12, 20],
        popupAnchor: [1, -17],
        shadowSize: [20, 20],
      }),

      redIcon: new L.Icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      }),

      blueIcon: new L.Icon({
        iconUrl:
          "https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png",
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      }),

      url: "https://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.377, 8.644),
      withTooltip: latLng(47.377, 8.544),
      currentZoom: 11.5,
      currentCenter: this.hereCenter(),
      showParagraph: false,
      showMap: true,
      visible: false,
      markers: [],
      tooltips: [],
      markerBounds: [
        [40.712, -74.227],
        [40.774, -74.125],
      ],
      mapOptions: {
        zoomSnap: 0.5,
      },
      markerClickedIndex: 0,
    };
  },

  mounted() {
    // this.doLog("mounted");
    // this.buildMarkers();
        window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    })
  },

  beforeMount() {
    //  this.doLog("beforeMount");
    // this.buildMarkers();
    if (!this.hasItems) {
      // this.$router.push("/");
    }
  },

  mixins: [myMixin],
  methods: {
    setCenter(event) {
      this.doLog(event);
      this.withTooltip = event.latlng;
      this.setLocation({ lat: event.latlng.lat, lon: event.latlng.lng });
      this.getItemsFrom(this.activeChannel.id);
      this.markerClickedIndex = 0;
    },

    getCountItems(channelid) {
      // console.log(channelid);
      this.$store.dispatch("COUNT_ITEMS", channelid);
    },

        getAge(when)
   {
// var relativeTime = require('dayjs/plugin/relativeTime')
// dayjs.extend(relativeTime)

return dayjs().to(dayjs(when)) // in 31 years
   }, 

    infiniteHandler: debounce(function ($state) {
      this.$store.dispatch("LOAD_ITEMS_MORE", {
        channel: this.activeChannel.ID,
        offset: this.fma,
        infiniteLoaderContext: $state,
      });
      //this.buildMarkers();
    }, 100),

    /*     switchTop(id) {
                      var index = this.$store.state.topIds.indexOf(id);
                      if (index >= 0) {
                        this.$store.state.topIds.splice(index, 1);
                      } else {
                        this.$store.state.topIds.push(id);
                      }
                      //    this.$store.dispatch('SAVE_TOPS');
                      index = this.$store.state.topIds.indexOf("");
                      if (index >= 0) {
                        this.$store.state.topIds.splice(index, 1);
                      }
                      this.$cookies.set("tops", this.$store.state.topIds.join(","));
                      this.doLog(this.$store.state.topIds.join(","));
                    }, */

    // MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP

    zoomUpdate(zoom) {
      // this.doLog("--- zoomUpdate: " + zoom);
      this.currentZoom = zoom;
    },

    boundUpdate(bounds) {
      // this.doLog("--- boundUpdate: " + bounds.getSouth());
    },

    centerUpdate(center) {
      // this.doLog("--- centerUpdate");
      //this.currentCenter = center;
    },

    visibilityChangeMapSmall() {
      // this.doLog("visibilityChangeMapSmall");
      this.$refs.mapsmall.mapObject.invalidateSize();
    },

    reloadMap: function () {
      // this.doLog("------ reloadMap ");
      // this.$refs.map_items.mapObject.invalidateSize();
    },

    doEvent: function (what) {
      // this.doLog("--- " + what);
      if (what == "input") {
        // this.$refs.map_items.mapObject.invalidateSize();
      }
      if (what == "ready") {
      }
      if (what != "click") {
        return;
      }
      setTimeout(() => {
        this.setBounds();
      }, 10);
    },

    getActive() {
      return true;
    },

    showBounds() {
      this.doLog(
        "   setBounds: " + this.$refs.map_items.mapObject.getBounds().getSouth()
      );
      this.doLog("--- " + this.markers.length);
    },

    setBoundsWatch: function () {
     //  this.doLog("-++ setBoundsWatch");
      // this.setBounds();
    },

    setBounds: function () {
      this.doLog("+++ setBounds");
      this.$refs.map_items.mapObject.invalidateSize();
      this.$refs.map_items.mapObject.fitBounds(this.markerBounds, {
        padding: [20, 20],
      });
      this.showBounds();
    },

    withMap: function () {
      return this.activeChannel.mapdisplay < 0;
    },

    buildMarkers() {
     // this.doLog("buildmarkers");
      if (!this.$refs.map_items) {
        return;
      }
      this.markers = [];
      this.tooltips = [];
      this.markerIcon = [];
      let latmin = this.hlat;
      let latmax = latmin;
      let lonmin = this.hlon;
      let lonmax = lonmin;
      L.NumberedDivIcon = L.Icon.extend({
        options: {
          // EDIT THIS TO POINT TO THE FILE AT http://www.charliecroom.com/marker_hole.png (or your own marker)
          // iconUrl: '@assets/images/marker_hole.png',
          iconUrl: "https://my.mapresso.com/markerhole.png",
          number: "",
          shadowUrl: null,
          iconSize: new L.Point(25, 41),
          iconAnchor: new L.Point(13, 41),
          popupAnchor: new L.Point(0, -33),
          /*
                                        iconAnchor: (Point)
                                        popupAnchor: (Point)
                                        */
          className: "leaflet-div-icon2",
        },

        createIcon: function () {
          var div = document.createElement("div");
          var img = this._createImg(this.options["iconUrl"]);
          var numdiv = document.createElement("div");
          numdiv.setAttribute("class", "number");
          numdiv.innerHTML = this.options["number"] || "";
          div.appendChild(img);
          div.appendChild(numdiv);
          this._setIconStyles(div, "icon");
          return div;
        },

        //you could change this to add a shadow like in the normal marker if you really wanted
        createShadow: function () {
          return null;
        },
      });

      //this.doLog("buildMarkers " + this.items.length);

      // this.$refs.map.mapObject.fitBounds(this.markers.map(m => { return [m.lat, m.lng] }))

      for (let i = 0; i < this.items.length; i += 1) {
        this.markers.push(latLng(this.items[i].lat, this.items[i].lon));
        if (i < 15) {
          this.markerIcon.push(new L.NumberedDivIcon({ number: i + 1 }));
          //this.markerIcon.push(new XX{ number: i + 1 }));
        } else {
          this.markerIcon.push(this.miniIcon);
        }
        this.tooltips.push(this.items[i].title);
        if (i < 16) {
          lonmin = Math.min(this.items[i].lon, lonmin);
          latmin = Math.min(this.items[i].lat, latmin);
          lonmax = Math.max(this.items[i].lon, lonmax);
          latmax = Math.max(this.items[i].lat, latmax);
        }
      }
      const bounds = [
        [latmin, lonmin],
        [latmax, lonmax],
      ];
      this.markerBounds = bounds;
    },

    getMarkerBounds(nitems) {
      this.doLog("getmarker");
      let latmin = 90;
      let latmax = -90;
      let lonmin = 180;
      let lonmax = -180;
      if (typeof nitems === "undefined") {
        nitems = 25;
      }
      for (let i = 0; i < this.items.length; i += 1) {
        //      for (let i = 0; i < nitems; i += 1) {
        lonmin = Math.min(this.items[i].lon, lonmin);
        latmin = Math.min(this.items[i].lat, latmin);
        lonmax = Math.max(this.items[i].lon, lonmax);
        latmax = Math.max(this.items[i].lat, latmax);
      }
      const bounds = [
        [latmin, lonmin],
        [latmax, lonmax],
      ];
      this.markerBounds = bounds;
      this.$refs.map_items.mapObject.fitBounds(this.markerBounds);
      this.doLog(this.markerBounds);
      return bounds;
    },

    // eslint-disable-next-line no-unused-vars
    visibilityChanged(isVisible, entry) {
      this.doLog("visibilityChanged");
      this.center = this.hereCenter;
      this.$refs.map_items.mapObject.invalidateSize();
    },

    // MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP MAP

    channelLastUpdate(when) {
      const lbd = moment(when, "YYYY-MM-DD");
      if (!lbd.isValid() || moment().diff(lbd, "days") < 1) {
        return " now";
      }
      return `Last Update:  ${lbd.fromNow()}`;
    },

    channelLink() {
      if (this.activeChannel.link === "") {
        return "";
      }
      if (this.activeChannel.linktext !== "") {
        return ` <a href='${this.activeChannel.link}'
        title='Source' target='_blank'>${this.activeChannel.linktext}</a>`;
      }
      return ` <a href='${this.activeChannel.link}' title='Source' target='_blank'>
       <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
      <img src = './images/icons8-externer-link-24.png' ></a>`;
    },

    getMeta1Channel(channel) {
      let res = "";
      res += "<table  border='1'><tr><th>Key</th><th>Value</th></tr>";
      Object.keys(channel).forEach((key) => {
        const value = channel[key];
        res += `<tr><td valign="top" style="font-size:80%">${key}</td><td valign="top" style="font-size:80%"><b>${value}</b></td></tr>`;
      });
      res += "</table";
      return res;
    },

    getMeta1Channel0(channel) {
      let res = "";
      Object.keys(channel).forEach((key) => {
        const value = channel[key];
        res += `${key}: <b>${value}</b><br>`;
      });
      return res;
    },

    showLongText() {
      this.showParagraph = !this.showParagraph;
    },

    innerClick(index) {
      document.getElementById("itemid" + index).scrollIntoView();
      this.markerClickedIndex = index;
      this.doLog(this.items[index]);
    },

    switchFav() {
      alert("lajdsflsajfa");
    },
  },
};
</script>

<style>
.leaflet-div-icon2 {
  background: transparent;
  border: none;
}

.leaflet-marker-icon .number {
  position: relative;
  top: -37px;
  font-size: 12.3px;
  width: 25px;
  text-align: center;
}

alignleft {
  float: left;
}

.alignright {
  float: right;
}
</style>
