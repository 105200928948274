<template>
  <div>
    <h2>Signup</h2>
    <form @submit="signup">
      <label for="email">Email:</label>
      <input
        type="email"
        id="email"
        v-model="email"
        required
      >

      <label for="password">Password:</label>
      <input
        type="password"
        id="password"
        v-model="password"
        required
      >

      <label for="firstName">First Name:</label>
      <input
        type="text"
        id="firstName"
        v-model="firstName"
      >

      <label for="lastName">Last Name:</label>
      <input
        type="text"
        id="lastName"
        v-model="lastName"
      >

      <button type="submit">
        Signup
      </button>
    </form>
    <p
      v-if="errorMessage"
      class="error"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
  
  <script>
export default {
  data() {
    return {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      errorMessage: "",
    };
  },
  methods: {
    signup(event) {
      event.preventDefault();

      // Perform form validation
      if (!this.email || !this.password) {
        this.errorMessage = "Please enter email and password";
        return;
      }

      // Make a POST request to your PHP signup endpoint
      fetch(this.$store.state.serverRoot + "/server/signup.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: this.email,
          password: this.password,
          first_name: this.firstName,
          last_name: this.lastName,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          // Check the response from the server
          if (data.success) {
            // Signup successful, redirect the user to the login page
            this.$router.push("/login");
          } else {
            // Signup failed, display the error message
            this.errorMessage = data.message;
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          this.errorMessage = "An error occurred during signup";
        });
    },
  },
};
</script>
  
  <style>
.error {
  color: red;
}
</style>
  