<template>
  <div>
    <h1>My Favorite Locations</h1>
    <div class="testexplain">
      Use the map to temporarily set a new location and explore its surroundings. Use the bookmark icon at the top to permanently favorite a place.
    </div>
    <br>

    <map-pref @shown="visiblemappref" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import MapPref from "@/components/MapPref.vue";
import myMixin from "@/myMixin.js";

export default {
  name: "FavoriteLocations",
  metaInfo() {
    const descr = "My Favorite Locations, Location Shortcuts.";
    return {
      title: "Favorite Locations",
      meta: [
        { name: "description", content: descr },
      ]
    };
  },

  mixins: [myMixin],

  components: {
    MapPref
  },

  mounted() {
    this.$store.dispatch("LOAD_ITEMS", 204);
    this.$store.dispatch("LOAD_CHANNEL", 204);
  },

  computed: {
    ...mapState(["mapItems", "mapChannel"]),
  },

  methods: {
    visiblemappref() {
      console.log("visiblemappref 1");
      this.$refs.map_favlocations.mapObject.invalidateSize();
    },   
   
  }
};
</script>
